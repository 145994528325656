import React, { Component } from 'react';
import {
    Container,
    Table,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardTitle, FormGroup, Form, Label,
    Spinner
} from 'reactstrap';

import { BsCheck, BsEnvelope } from "react-icons/bs";

import ManagerContext from '../context/ManagerContext';

var DatePicker = require("reactstrap-date-picker");


function filterState(client, stateId) {
    return client.stateId === stateId;
}


export class Reports extends Component {
    static displayName = Reports.name;

    constructor(props, context) {
        super(props, context);
    }


    render() {
        let context = this.context;

        if (context.IsLoggedIn()) {
            if (this.state != null) {

                return (

                    <Container fluid>
                        <Card body className="text-center">
                            <CardTitle tag={'h3'}>Reports</CardTitle>
                        </Card>
                        <br />
                        <Card body>
                            
                        </Card>
                  

                    </Container>
                );
            } else {
                return (
                    <Container fluid>
                        Please wait while we load this page for you...
                    </Container>
                );
            }
        } else {
            return (
                <Container fluid>
                    <p>Please ensure that you have logged in</p>
                </Container>
            );
        }

    }
}


Reports.contextType = ManagerContext;
