import { isNil } from 'lodash';
import Authentication from './module/Authentication';
import { format } from 'date-fns';


const _Data = {
    activeJobs: [],
    lastRefresh: 0,
    recentCompletedJobs: [],
    timer: null,
    filteredJobs: [],
    trackAndTraceUrl: null
};

const _Observers = {
    jobObservers: [],
    refreshObservers: []
}

var RefreshRunning = false;



function jobDataBroadcast() {
    _Observers.jobObservers.forEach(observer => {
        observer(_Data.activeJobs);
    });

    _Observers.refreshObservers.forEach(observer => {
        observer({ time: _Data.lastRefresh });
    });
}




export function RegisterJobObserver(observer) {
    _Observers.jobObservers.push(observer);
}

export function DeregisterJobObserver() {
    _Observers.jobObservers = [];
}

export function RegisterRefreshObserver(observer) {
    _Observers.refreshObservers.push(observer);
}

export function DeregisterRefreshObserver() {
    _Observers.refreshObservers = [];
}

export function RecentJobs() {
    return _Data.recentCompletedJobs;
}

/* IMPORTANT */
export function RefreshData(data) {
    if (data === 'Refresh')
    {
        if (RefreshRunning) return;

        RefreshRunning = true;
        // LoadData()
        // .then(LoadRecentCompletedData)
        // .then(jobDataBroadcast)
        // .then(() => { RefreshRunning = false; });
    }
}


function LoadData() {
    return new Promise(function (resolve, reject) {
        fetch('api/Jobs/ByAccount', {
            headers: {
                Authorization: 'Bearer ' + Authentication.User.jwtToken
            }
        })
            .then(data => {
                _Data.activeJobs = data.json();
                _Data.lastRefresh = new Date().getTime();
                resolve(_Data.activeJobs);
            })
            .catch((error) => {
                reject('Could not load data');
            });
    });
}

function LoadActiveRouteData() {
    return new Promise(function (resolve, reject) {
        fetch('api/Jobs/ActiveRoutes', {
            headers: {
                Authorization: 'Bearer ' + Authentication.User.jwtToken
            }
        })
            .then(data => {
                //_Data.activeJobs = data.json();
                //_Data.lastRefresh = new Date().getTime();
                //resolve(_Data.activeJobs);
                return Promise.resolve(data.text());
            })
            .catch((error) => {
                reject('Could not load data');
            });
    });
}

export function GetData() {
    return new Promise(function (resolve, reject) {
        if (Authentication.IsLoggedIn()) {
            resolve(_Data.activeJobs);
        } else {
            reject('Not Logged In');
        }
    });
}

function LoadRecentCompletedData() {
    return new Promise(function (resolve, reject) {
        fetch('api/RecentCompletedJobs/RecentCompleted', {
            headers: {
                Authorization: 'Bearer ' + Authentication.User.jwtToken
                }
            })
            .then(data => {
                return data.json()
            })
            .then(recentCompletedJobs => {
                _Data.recentCompletedJobs = recentCompletedJobs;
                _Data.lastRefresh = new Date().getTime();
                resolve(_Data.recentCompletedJobs);
            })
            .catch((error) => {
                reject('Could not load recent completed data');
            });
    });
}

export function GetRecentCompletedData() {
    return new Promise(function (resolve, reject) {
        if (Authentication.IsLoggedIn()) {
            resolve(_Data.recentCompletedJobs);
        } else {
            reject('Not Logged In');
        }
    });
}

function LoadFilteredData(searchString, searchBy) {
    return new Promise(function (resolve, reject) {
        fetch("api/Search", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + Authentication.User.jwtToken,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                reference: searchString,
                referenceType: searchBy
            })
        })
            .then(data => {
                _Data.filteredJobs = data.json();
                resolve(_Data.filteredJobs);
            })
            .catch(error => {
                reject('Could not set search inputs');
            });
    });
}

export function GetFilteredData(searchString, searchBy) {
    return new Promise(function (resolve, reject) {
        if (Authentication.IsLoggedIn()) {
            //console.log('Last Refresh: ' + _Data.lastRefresh);
            LoadFilteredData(searchString, searchBy)
                .then(() => { resolve(_Data.filteredJobs); })
                .catch((error) => { console.error('Failed Loading: ', error); });
        } else {
            reject('Not Logged In');
        }
    });
}

//function SetSearchInputs(filterReference) {
//    return new Promise(function (resolve, reject) {
//        fetch("api/Search", {
//            method: "POST",
//            headers: {
//                'Authorization': 'Bearer ' + Authentication.User.jwtToken,
//                'Content-Type': 'application/json',
//                'Accept': 'application/json'
//            },
//            body: JSON.stringify({
//                'reference': filterReference
//            })
//        })
//        .then(response => {
//            resolve(response.json);
//        })
//        .catch(error => {
//            reject('Could not set search inputs');
//        });
//    });
//}

//export function InitialiseFilteredData(filterReference) {
//    return new Promise(function (resolve, reject) {
//        if (Authentication.IsLoggedIn()) {
//            //console.log('Last Refresh: ' + _Data.lastRefresh);
//            SetSearchInputs(filterReference)
//                .then(() => { resolve(); })
//                .catch((error) => { console.error('Failed Initialising: ', error); });
//        } else {
//            reject('Not Logged In');
//        }
//    });
//}

export function LoadTrackAndTraceData(filterReference, user, accountCode, stateId) {
    return fetch("api/TrackAndTrace", {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Basic ' + window.btoa(user.xCabUser + ':' + user.xCabPassword),
            'Content-Type': 'application/json',
            'ApiKey': user.xCabSharedKey
        }),
        body: JSON.stringify({
            'AccountCode': isNil(accountCode) ? user.clientCode : accountCode,
            'ReferenceNumber': filterReference,
            'State': isNil(stateId) ? user.stateId : stateId
        })
    })
    .then(response => {
        return Promise.resolve(response.text());
    })
    .catch(error => {
        return Promise.reject('Could not load track and trace data');
    });
}

export function LoadTrackAndTraceDataUsingClientCodeAndState(filterReference, clientCode, stateId, user) {
    return new Promise(function (resolve, reject) {
        fetch("api/TrackAndTrace", {
            method: "POST",
            headers: new Headers({
                'Authorization': 'Basic ' + window.btoa(user.xCabUser + ':' + user.xCabPassword),
                'Content-Type': 'application/json',
                'ApiKey': user.xCabSharedKey
            }),
            body: JSON.stringify({
                'AccountCode': clientCode,
                'ReferenceNumber': filterReference,
                'State': stateId
            })
        })
            .then(response => {
                resolve(response.text());
            })
            .catch(error => {
                reject('Could not load track and trace data');
            });
    });
}

export function LoadTrackAndTraceDataFromSearch(filterReference, user, account, stateId) {
    return new Promise(function (resolve, reject) {
        fetch("api/TrackAndTrace", {
            method: "POST",
            headers: new Headers({
                'Authorization': 'Basic ' + window.btoa(user.xCabUser + ':' + user.xCabPassword),
                'Content-Type': 'application/json',
                'ApiKey': user.xCabSharedKey
            }),
            body: JSON.stringify({
                'AccountCode': account,
                'ReferenceNumber': filterReference,
                'State': stateId
            })
        })
            .then(response => {
                resolve(response.text());
            })
            .catch(error => {
                reject('Could not load track and trace data');
            });
    });
}


export function GetActiveRoutes() {
    Authentication.CheckStoredSession();

    return fetch('api/jobs/ActiveRoutes', {
       method: 'GET',
       headers: {
           Authorization: 'Bearer ' + Authentication.User.jwtToken
       }
    })
    .then(response => {
       return Promise.resolve(response.json());
    })
    .catch(error => {
       Promise.reject('Could not load active routes data');
    });
}