import { format } from 'date-fns';
import { LoadTrackAndTraceData, LoadTrackAndTraceDataUsingClientCodeAndState } from '../Service';


export class UtilsService {
    
    stateAbbrevToStateId(stateAbbrev) {
        var stateId = 0;
    
        switch (stateAbbrev) {
            case 'M':
                stateId = 1;
                break;
            case 'S':
                stateId = 2;
                break;
            case 'B':
                stateId = 3;
                break;
            case 'A':
                stateId = 4;
                break;
            case 'P':
                stateId = 5;
                break;
            case "VIC":
                stateId = 1;
                break;
            case "NSW":
                stateId = 2;
                break;
            case "QLD":
                stateId = 3;
                break;
            case "SA":
                stateId = 4;
                break;
            case "WA":
                stateId = 5;
                break;
        }
    
        return stateId;    
    }


    /**
     * Returns the lat and lng coordinates that represents the center of the state provided.
     */
    stateCenterCoordinates(stateId) {
        let stateCoordinates = {};
        
        switch(stateId) {
            case 1 : /* VIC */ 
                stateCoordinates = { lat: -37.8136, lng: 144.9631 }
            break;
            case 2 : /* NSW */
                stateCoordinates = { lat: -33.8688, lng: 151.2093 }
            break;
            case 3 : /* QLD */
                stateCoordinates = { lat: -27.4698, lng: 153.0251 }
            break;
            case 4 : /* SA */
                stateCoordinates = { lat: -34.9285, lng: 138.6007 }
            break;
            case 5 : /* WA */
                stateCoordinates = { lat: -31.9505, lng: 115.8605 }
            break;
        }

        return stateCoordinates;
    }


    /**
     * Returns the name/word representation of a JobEventEnum value
     */
    jobEventEnumName(jobEventEnum) {
        let name = '';

        switch (jobEventEnum) {
            case 0:
                name = 'Job Booked';
            break;
            case 1:
                name = 'Job Update';
            break;
            case 2:
                name = 'Job Allocated';
            break;
            case 3:
                name = 'Pickup Arrived';
            break;
            case 4:
                name = 'Pickup Completed';
            break;
            case 5:
                name = 'Delivery Arrived';
            break;
            case 6:
                name = 'Delivery Completed';
            break;
            case 7:
                name = 'Cancelled';
            break;
            case 8:
                name = 'Completed';
            break;
            case 9:
                name = 'GPS';
            break;
        }

        return name;
    }


    formatDateToDefault(date) {
        return format(new Date(date), 'dd MMM yyyy HH:mm:ss');
    }


    /**
     * Loads the track and trace data then opens the job tracking page
     */
    openJobTrackingPage(jobNumber, userInfo) {
        LoadTrackAndTraceData(jobNumber, userInfo)
        .then((data) => {
            window.open(data, '_blank');
        })
        .catch((error) => { console.error("Failed!", error); });
    }

    openJobTrackingPageUsingClientCodeAndState(jobNumber, clientCode, stateAbbrev, userInfo) {
        LoadTrackAndTraceDataUsingClientCodeAndState(jobNumber, clientCode, this.stateAbbrevToStateId(stateAbbrev), userInfo)
        .then((data) => {
            window.open(data, '_blank');
        })
            .catch((error) => { console.error("Failed!", error); });
    }

    downloadFile(details, legs, legnumber) {
        let vals = {
            Details: details,
            Legs: legs,
            Legnumber: legnumber
            
        };
        let today = new Date();
        let time = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate() + '_' + today.getHours() + today.getMinutes() + today.getSeconds();
        let fileName = 'POD_' + details.id +'_' + time + '.pdf';
        return fetch('api/ExportPod/ExportPDF', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(vals)
        })
        .then((response) => response.blob())
        .then((blob) => {
            console.log(blob);
            
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
    }


    /**
     * Determines if the provided clientCode is one of the Sigma accounts.
     */
    isClientCodeSigmaRelated(clientCode) {
        const sigmaCodes = [
            'KMSIGMA',
            'KBSIGMA',
            'KASIGPHA',
            'KSSIGM',
            'KDSIGMA',
            'KPSIGPRM',
            'KMSIGTRU',
        ];
        
        if(sigmaCodes.includes(clientCode?.toUpperCase())) {
            return true;
        } else {
            return false;
        }
    }
    
}