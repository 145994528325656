export default class Authentication {

    static User = null;

    constructor() {
        this.User = new UserClass();
    }

    static handleErrors = (response) => {
        if (!response.ok) {
            console.log(response);
        }
        return response.json();
    }

    static IsLoggedIn = () => {
        var ret;
        if (this.User?.jwtToken?.length > 5) {
            ret = true;
        } else {
            ret = false;
        }

        return ret;
    }

    static IsAsnUser = () => {
        return (this.User?.allowAsn === true);
    }

    static GetAllowedClients = () => {
        return this.User.extraClients;
    }

    static OscLogin = (token) => {
        return fetch('api/Authentication/Authenticate', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'token': token })
        })
            .then(this.handleErrors)
            .then(data => {
                this.User = data.user;
                this.User.jwtToken = data.token;
                this.User.password = '';

                this.SetLocalSession(this.User);
                console.log(data);
                return this.User;
            });
    }

    static AsnLogin(username, password) {
         return fetch('api/Login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'username': username, 'password': password })
        })
            .then(this.handleErrors)
            .then(data => {
                this.User = data.user;
                this.User.jwtToken = data.token;
                this.User.password = '';

                this.SetLocalSession(this.User);

                return this.User;
            });
    }

    static LogOut = (logoutCallback) => {
        var jwt = this.User.jwtToken;

        this.TerminateSession(jwt)
        .then(() => {
            this.User = new UserClass();
            this.ClearLocalSession();
            
            if(logoutCallback) {
                logoutCallback();
            }
        });      
    }

    static CheckStoredSession = () => {
        if (sessionStorage.user) {
            this.User = JSON.parse(sessionStorage.user);
        }
        return this.User;
    }

    static SetLocalSession = (user) => {
        sessionStorage.user = JSON.stringify(user);
    }



    static ClearLocalSession = () => {
        sessionStorage.clear();
    }

    static TerminateSession = (jwt) => {
        return fetch("api/Logout", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                'IsLoggedIn': false
            })
        })
        .then(response => {
            Promise.resolve(response.json);
        })
        .catch(error => {
            Promise.reject('Could not send logout and terminate signal');
        });
    }

    /*
 * LEGACY FUNCTIONS
 * TO REMOVE AS MODULES ARE COMPLETED
 * */

    getJwt = () => {
        return this.User.jwtToken;
    }

    static getUser = () => {
        return this.User;
    }

    getRawUser = () => {
        return this.User;
    }

/* ****************************** */
}


/* MUST MATCH ilogix.dashboard.data.Authentication.Model.UserModel !!! */
export class UserClass {
    jwtToken = '';
    username = '';
    password = '';
    email = '';
    clientCode = '';
    clientName = '';
    stateId = '';
    clientBrand = '';
    oscToken = '';
    xCabUser = '';
    xCabSharedKey = '';
    xCabPassword = '';
    multiAccount = false;
    allowHds = false;
    allowAsn = false;
    extraClients = [
        {
            clientCode : '',
            clientName : '',
            clientBrand : '',
            stateId : ''
        }
    ]
}