import './SingleJobDetail.css';
import React, { Component } from 'react';
import { isNumber } from 'lodash';
import { format } from 'date-fns';
import {
    UncontrolledCollapse,
    Container, Table, Button, Row
} from 'reactstrap';
import { Carousel } from 'primereact/carousel';
import { Button as PrimeButton } from 'primereact/button';
import Common from '../../module/Common';
import { UtilsService } from '../../services/UtilsService';


// interface Barcode {
//     barcode: string;
//     description: string;
//     displayItemType: boolean;
// }


// interface CarouselInstance {
//     page: Number;
//     numberOfItems: number;
// }


export class SingleJobDetail extends Component {

    constructor() {
        super();
        
        this.state = {};
    }
    
    
    static displayName = SingleJobDetail.name;
    utilsService = new UtilsService();
    carouselAutoplayInterval;
    carouselInstances = []; /* CarouselInstances[] */


    componentDidMount() {
        // this.manageCarouselAutoplay(); /* We temporarily display the autoplay feature so we just comment out this line of code for now. */
    }


    componentWillUnmount() {
        clearInterval(this.autoplayInterval);
    }


    /**
     * Return Barcode[] extracted from references.
     * @param references Dictionary<string, Barcode>
     */
    getBarcodesFromReferences(references) {
        let barcodesUnderReferences = [];

        Object.keys(references)?.forEach(referenceName => {
            const referenceBarcodes = references[referenceName];
            if(referenceBarcodes instanceof Array) {
                barcodesUnderReferences = barcodesUnderReferences.concat(referenceBarcodes);
            }
        });

        return barcodesUnderReferences;
    }

    
    /**
     * Determines if the provided reference belongs to either "reference1" or "reference2".
     * Will return either "Ref1" or "Ref2".
     */
    referenceBelongsTo(referenceName) {
        const ref1Data = this.props.trackingDetails.details.reference1;
        const ref2Data = this.props.trackingDetails.details.reference2;

        if(ref1Data.indexOf(referenceName) !== -1) {
            return 'Ref1';
        } else if(ref2Data.indexOf(referenceName) !== -1) {
            return 'Ref2';
        } else {
            return '';
        }
    }


    /**
     * Autoplay functionality have issue where sometimes it is not working.
     * Also stops working if the user interacted with the carousel or when click any of the controls inside it.
     * To fix these issues, we will just manually invoke the "navForward" method of each Carousel instance in this component.
     * Note that this component have multiple Carousels.
     */
    manageCarouselAutoplay() {
        const autoplayInterval = 5000; /* 5 seconds */
        
        if(!this.carouselAutoplayInterval) {
            this.carouselAutoplayInterval = setInterval(() => {
                this.carouselInstances?.forEach(carousel => {
                    carousel?.navForward(() => {});
                    this.setState({});
                })
            }, autoplayInterval)
        }
    }


    carouselPrev(carouselIndex) {
        const carousel = this.carouselInstances[carouselIndex];

        if(carousel.page > 0) {
            carousel.page--;
        } else {
            carousel.page = carousel.numberOfItems - 1;
        }

        this.setState({});
    }


    carouselNext(carouselIndex) {
        const carousel = this.carouselInstances[carouselIndex];

        if(carousel.page < (carousel.numberOfItems - 1)) {
            carousel.page++;
        } else {
            carousel.page = 0;
        }

        this.setState({});
    }
    
    
    /**
     * Render the list of barcodes under a specific reference
     * @param referenceName string
     * @param barcodes Barcode[]
     */
    renderReferenceBarcodes(referenceName, barcodes) {
        const belongsTo = this.referenceBelongsTo(referenceName);
        const belongsToText = belongsTo ? belongsTo + ' : ' : '';
        const referenceNameMarkup = (<b>{belongsToText}{referenceName}</b>);
        const displayItemTypeColumn = barcodes?.find(x => x.displayItemType) ? true : false; /* Show "Item Type" column only if at least one of the barcodes in the current reference has `displayItemType: true` */
        const barcodesContent = barcodes?.map((barcode, barcodeIndex) => {
            const barcodeDescription = (barcode?.description && barcode?.displayItemType) ? barcode?.description : '';
            
            return (
                <tr key={barcodeIndex}>
                    <td className="barcode">{barcode?.barcode}</td><td>{barcodeDescription}</td>
                </tr>
            );
        });

        if(barcodes?.length) {
            return (
                <div className='barcodes-by-reference'>
                    {referenceNameMarkup}
                    <Table striped hover borderless size="sm">
                        <thead>
                            <tr>
                                <th>Barcode</th><th>{displayItemTypeColumn ? 'Item Type' : ''}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {barcodesContent}
                        </tbody>
                    </Table>
                </div>
            )
        } else {
            return (
                <div>
                    {referenceNameMarkup}
                    <br/>
                    <i>- No barcodes for this reference</i>
                </div>    
            );
        }
    }
    
    
    /**
     * Generate HTML that displays a list of references with barcodes listed under.
     * @param references The dictionary that contains barcodes grouped by references(reference name used as key)
     */
    renderReferencesWithBarcodes(references) {
        const barcodesUnderReferences = this.getBarcodesFromReferences(references);
        
        const referenceKeys = Object.keys(references);
        const noBarcodes = (<i>No barcodes available</i>);
        const barcodesByReferences = referenceKeys.map((referenceName, referenceIndex) => {
            return <div key={referenceIndex}>
                {this.renderReferenceBarcodes(referenceName, references[referenceName])}
            </div>
        })
        
        if(barcodesUnderReferences?.length) {
            return barcodesByReferences;
        } else {
            return noBarcodes;
        }
    }


    /**
     * Generate a table listing the barcode exceptions.
     */
    renderBarcodeExceptions(barcodeExceptions, displayBarcodeExceptions) {
        if(displayBarcodeExceptions && barcodeExceptions?.length) {
            const barcodeExceptionsContent = barcodeExceptions?.map(barcodeException => {
                return <tr>
                    <td>{barcodeException.barcode}</td>
                    <td>{barcodeException.exceptionReason}</td>
                    <td>{barcodeException.authName}</td>
                </tr>
            })
            
            return <div className="barcode-exceptions">
                <strong>Barcode Exceptions: {barcodeExceptions.length}</strong>
                <br />
                <Table striped hover borderless size="sm">
                    <thead>
                        <tr>
                            <th>Barcode</th>
                            <th>Exception Reason</th>
                            <th>Auth Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {barcodeExceptionsContent}
                    </tbody>
                </Table>
            </div>
        }
    }

    /**
     * Render table rows for routeCode and dropNumber
     * Show only if "showRouteCode" flag is true
     */
    renderRouteCodeRows(routeCode, dropNumber, showRouteCode) {
        if (showRouteCode === true) {
            return <>
                <tr><th>Route Code</th><td className="value">{routeCode}</td></tr>
                <tr><th>Drop Number</th><td className="value">{dropNumber}</td></tr>
            </>
        }
    }


    /**
     * Render a table that lists the barcodes alongside with its type.
     * Type will be shown based on barcode.displayItemType
     */
    renderBarcodesWithType(barcodesGroupedByReferences) {
        const barcodes = this.getBarcodesFromReferences(barcodesGroupedByReferences);

        return <Table hover striped size="sm">
            <thead>
                <tr>
                    <th>Barcode</th>
                    <th>Item Type</th>
                </tr>
            </thead>
            <tbody>{
                barcodes.map((barcode, barcodeIndex) => {
                    const barcodeDescription = (barcode?.displayItemType) ? barcode.description : '';
                    
                    return <tr key={barcodeIndex}>
                        <td className="barcode">{barcode.barcode}</td>
                        <td>{barcodeDescription}</td>
                    </tr>
                })
            }</tbody>
        </Table>
    }


    renderCarouselItem(image) {
        return <div className="carousel-image-container flex flex-column justify-content-end gap-3">
            <img src={image.src} alt={image.altText} />
            <div className="caption">{image.caption}</div>
        </div>
    }


    renderCarousel(images, index) {
        const currentPage = isNumber(this.carouselInstances?.at(index)?.page) ? this.carouselInstances?.at(index)?.page + 1 : 1;
        const carouselClass = (images?.length === 1) ? 'single-item' : 'multiple-item';
        
        if(images.length) {
            return <div className={'carousel-container flex flex-column justify-content-center ' + carouselClass}>
                <Carousel 
                    value={images} 
                    ref={(e) => {
                        if(!this.carouselInstances[index]) {
                            this.carouselInstances[index] = { page: 0, numberOfItems: images.length }
                        }
                    }}
                    itemTemplate={(image) => this.renderCarouselItem(image)}
                    page={this.carouselInstances[index]?.page ?? 0}
                />

                <div className="prev-next-buttons flex flex-row justify-content-between">
                    <PrimeButton icon="pi pi-angle-left" className="p-button-rounded p-button-text" onClick={() => { this.carouselPrev(index) }} />
                    <PrimeButton icon="pi pi-angle-right" className="p-button-rounded p-button-text" onClick={() => { this.carouselNext(index) }} />
                </div>

                <div className="carousel-custom-page-indicator">{currentPage} of {images.length}</div>
                <div className="text-center">
                    <PrimeButton icon="pi pi-download" label={'Download ' + images[0].caption} className="download-file-button p-button-link p-button-sm" onClick={() => {
                        this.utilsService.downloadFile(this.props.trackingDetails.details, this.props.trackingDetails.legs, index)
                    }} />
                </div>
            </div>
        }
    }
    
    
    render() {
        const td = this.props.trackingDetails;

        return (
            <div className='single-job-detail'>
                <Row>
                    <Button color="primary" id="toggleOverall" size="sm" block>Overall Job Details</Button>
                    <Container>
                        <UncontrolledCollapse toggler="#toggleOverall" ><br />
                            <Table hover striped size="sm">
                                <tbody>
                                    <tr><th>Job Number</th><td className="value">{td.details.id}</td></tr>
                                    <tr><th>Service Code</th><td className="value">{td.details.serviceCode}</td></tr>
                                    <tr><th>Customer</th><td className="value">{td.details.customer}</td></tr>
                                    <tr><th>Consignment</th><td className="value">{td.details.consignment}</td></tr>
                                    <tr><th>Reference1</th><td className="value">{td.details.reference1}</td></tr>
                                    <tr><th>Reference2</th><td className="value">{td.details.reference2}</td></tr>
                                    {this.renderRouteCodeRows(td.details.routeCode, td.details.dropNumber, td.details.showRouteCode)}
                                    <tr><th>Driver</th><td className="value">{td.driver.id}</td></tr>
                                    <tr><th>Job Status</th><td className="value">{td.details.status}</td></tr>
                                    <tr><th>Date Booked</th><td className="value">{(td.details.dateBooked) ? format(new Date(td.details.dateBooked), 'dd MMM yyyy') : ''}</td></tr>
                                    <tr><th>Last Updated</th><td className="value">{(td.details.lastUpdate) ? format(new Date(td.details.lastUpdate), 'dd MMM yyyy HH:mm:ss') : ''}</td></tr>
                                    <tr><th>Number of Legs</th><td className="value">{td.legs.length}</td></tr>
                                </tbody>
                            </Table>

                            <div className="barcodes-grouped-by-references">
                                <strong>Barcodes : {this.getBarcodesFromReferences(td.details?.barcodesGroupedByReferences)?.length}</strong>
                                <br/>
                                {this.renderReferencesWithBarcodes(td.details?.barcodesGroupedByReferences)}
                            </div>

                            {/* {this.renderBarcodesWithType(td?.details?.barcodesGroupedByReferences)} */}
                        </UncontrolledCollapse>
                    </Container>
                </Row>
                <Row>
                    <Button color="primary" id="toggleStatus" size="sm" block>Status Summary</Button>
                    <Container>
                        <UncontrolledCollapse toggler="#toggleStatus" ><br />
                            <Table hover striped size="sm">
                                <tbody>
                                    <tr>
                                        <th>Status</th>
                                        <th>Time (AEST)</th>
                                        <th>Location</th>
                                        <th>Info</th>
                                    </tr>
                                    {td.statuses.map(status => (
                                        <tr>
                                            <td className="value">{status.event}</td>
                                            <td className="value">{Common.defaultDateFormat(status.time)}</td>
                                            <td className="value">{status.location}</td>
                                            <td className="value">{status.info}</td>

                                        </tr>
                                     ))}                                    
                                </tbody>
                            </Table>
                        </UncontrolledCollapse>
                    </Container>
                </Row>
                <Row>
                    <Button color="primary" id="toggleDetention" size="sm" block>Detention Summary</Button>
                    <Container>
                        <UncontrolledCollapse toggler="#toggleDetention" ><br />
                            <Table hover striped size="sm">
                                <tbody>
                                    <tr>
                                        <th>Type</th>
                                        <th>Arrived</th>
                                        <th>Completed</th>
                                        <th>Entered Time</th>
                                        <th>Comp. Time</th>
                                    </tr>
                                    {td.detentions.map(detention => (
                                        <tr>
                                            <td className="value">{detention.type}</td>
                                            <td className="value">{Common.defaultDateFormat(detention.arrived)}</td>
                                            <td className="value">{Common.defaultDateFormat(detention.completed)}</td>
                                            <td className="value">{detention.timeEntered}</td>
                                            <td className="value">{detention.timeCalculated}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </UncontrolledCollapse>
                    </Container>
                </Row>


                {td.legs.map((leg, legIndex) =>
                    <Row key={legIndex}>
                        <Button color="primary" id={leg.legType + leg.legNumber + 'Toggle'} size="sm" block>{leg.legType} {leg.legType==="Pickup" ? '' :' - Leg ' + leg.legNumber }</Button>
                        <Container>
                            <UncontrolledCollapse toggler={'#' + leg.legType + leg.legNumber + 'Toggle'}><br />
                                <Table hover striped size="sm">
                                    <tbody>
                                        <tr><th>Last Updated</th><td className="value">{(leg.lastUpdated) ? format(new Date(leg.lastUpdated), 'dd MMM yyyy HH:mm:ss') : ''}</td></tr>
                                        <tr><th>Ref 1</th><td className="value">{leg.ref1}</td></tr>
                                        <tr><th>Ref 2</th><td className="value">{leg.ref2}</td></tr>
                                        <tr><th>Consignment</th><td className="value">{leg.consignment}</td></tr>
                                        <tr><th>Address</th><td className="value">{leg.address}</td></tr>
                                        <tr><th>Suburb</th><td className="value">{leg.suburb}</td></tr>
                                        <tr><th>Special Instructions</th><td className="value">{leg.specialInstructions}</td></tr>
                                        <tr><th>Comments</th><td className="value">{leg.comments}</td></tr>
                                    </tbody>
                                </Table>

                                <div className="barcodes-grouped-by-references">
                                    <strong>Barcodes : {this.getBarcodesFromReferences(leg?.barcodesGroupedByReferences ?? [])?.length}</strong>
                                    <br/>
                                    {this.renderReferencesWithBarcodes(leg?.barcodesGroupedByReferences ?? [])}
                                </div>

                                {this.renderBarcodeExceptions(leg.barcodesExceptions, leg.displayBarcodeException)}

                                
                                {this.renderCarousel(leg.images, legIndex)}
                            </UncontrolledCollapse>
                        </Container>
                    </Row>
                )}

                <Row>
                    <Button color="primary" id="toggleDriverSummary" size="sm" block>Driver Summary</Button>
                    <Container>
                        <UncontrolledCollapse toggler="#toggleDriverSummary" ><br />
                            <Table hover striped size="sm">
                                <tbody>
                                    {this.renderRouteCodeRows(td.details.routeCode, td.details.dropNumber, td.details.showRouteCode)}
                                    <tr><th>Driver Name</th><td className="value">{td.driver.name}</td></tr>
                                    <tr><th>Driver Number</th><td className="value">{td.driver.id}</td></tr>
                                    <tr><th>Remaining Deliveries</th><td className="value">{td.driver.incompleteLegs.length}</td></tr>
                                </tbody>
                            </Table>
                        </UncontrolledCollapse>
                    </Container>
                </Row>
                
            </div>
        );
    }
}