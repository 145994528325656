export default class Track {

    static GetTrackableJob = (token) => {
        return fetch('api/tracking/token', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'guid': token })
        })
            .then(this.handleErrors)
            .then(data => {
                return data;
            });
    }

    static GetTrackableJobTT = (payload) => {
        return fetch('api/tracking/user', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(this.handleErrors)
            .then(data => {
                return data;
            });
    }

    static handleErrors = (response) => {
        if (response.status === 200) {
            return response.json();
        }
        return 'failure';
    }


    static getTestPacket = () => {
        return {
            job: {
                details: {
                    id: '010721S00244123',
                        customer: 'KOALASYD',
                            status: 'Complete',
                                dateBooked: '30 Jun 2021',
                                    lastUpdate: '01 Jul 2021 13:47'
                },
                statuses: [
                    { event: 'Picked Up', time: '01 Jul 2021 09:32', location: '13 Wulbanga Street, Prestons', info: 'nsr (Pickup)' },
                    { event: 'Delivered', time: '01 Jul 2021 11:32', location: '48-50 Leichardt St, Ruse', info: ' paige fitzgerald ' },
                    { event: 'Completed', time: '01 Jul 2021 11:32', location: '48-50 Leichardt St, Ruse', info: 'Delivery Leg 1' }
                ],
                    detentions: [
                        { type: 'Picked Up', arrived: '01 Jul 2021 09:32', completed: '01 Jul 2021 09:33', timeEntered: '00:00', timeCalculated: '00:01' },
                        { type: 'Delivered', arrived: '01 Jul 2021 11:32', completed: '01 Jul 2021 11:32', timeEntered: '00:00', timeCalculated: '00:00' }
                    ],
                        legs: [
                            {
                                legType: 'Pickup',
                                legNumber: 1,
                                lastUpdated: '01 Jul 2021 09:32',
                                ref1: '',
                                ref2: '',
                                address: '13 Wulbanga Street, Prestons',
                                specialInstructions: 'Don\'t hurt fred',
                                comments: '',
                                barcodes: ['asdf', 'asdf'],
                                location: { lat: -33.815692, long: 151.119148 },
                                images: [
                                    {
                                        src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
                                        altText: 'POD Alt',
                                        header: ' ',
                                        caption: 'Pod Image',
                                        key: '1'
                                    }, {
                                        src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
                                        altText: 'POD Alt',
                                        header: ' ',
                                        caption: 'POC Image',
                                        key: '2'
                                    }, {
                                        src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
                                        altText: 'POD Alt',
                                        header: ' ',
                                        caption: 'POC Image',
                                        key: '3'
                                    }

                                ]
                            },
                            {
                                legType: 'Delivery',
                                legNumber: 1,
                                lastUpdated: '01 Jul 2021 11:32',
                                ref1: 'test ref',
                                ref2: 'test ref2',
                                address: '48-50 Leichardt St, Ruse',
                                specialInstructions: '',
                                comments: 'modified by x',
                                barcodes: [],
                                location: { lat: -33.810692, long: 151.029948 },
                                images: [
                                    {
                                        src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
                                        altText: 'POD Alt',
                                        caption: 'Pod Image',
                                        header: ' ',
                                        key: 'P1'
                                    }]
                            }
                        ]
            },
            driver: {
                id: '2025',
                name: 'Frank',
                location: { lat: -33.820692, long: 151.039948 },
                incompleteLegs: [
                    { lat: -33.810692, long: 151.209848, jobNumber: '162774' },
                    { lat: -33.840592, long: 151.049948, jobNumber: '162778' },
                    { lat: -33.870692, long: 151.019948, jobNumber: '171885' },
                    { lat: -33.890692, long: 151.159948, jobNumber: '183455' },
                    { lat: -33.720692, long: 151.139948, jobNumber: '183456' }
                ],
                completeLegs: [
                    { lat: -33.70692, long: 150.939848, jobNumber: '185864' },
                    { lat: -33.800592, long: 150.949948, jobNumber: '16218' },
                ]
            }

        }

    }

}