import './HistoricalReplayGrid.css';
import CsvLogo from '../../../assets/csv.png';

import { Component } from 'react';
import { Spinner } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { format } from 'date-fns';
import { UtilsService } from '../../../services/UtilsService';


// interface HistoricalReplayGridProps {
//     gpsPings: GpsPing[];
//     loadingGpsPings: boolean;
//     rowClicked: GpsPing
// }



export class HistoricalReplayGrid extends Component {

    utilsService = new UtilsService();
    
    
    constructor(props) {
        super(props);
    }

    
    emitRowClicked(gpsPing) {
        if(this.props?.rowClicked) {
            this.props.rowClicked(gpsPing);
        }
    }


    formatDate(date) {
        return format(new Date(date), 'dd-MMM-yyyy');
    }


    formatTime(date) {
        return format(new Date(date), 'HH:mm:ss');
    }


    /**
     * Returns an object representing a set of custom class names to be applied on tr.
     */
    generateRowClassName(rowData) {
        return {
            'vehicle-stopped': rowData.vehicleStopped
        };
    }


    renderTableActions() {
        if(this.props?.loadingGpsPings === true) {
            return null;
        } else {
            return <div className="table-actions flex flex-row gap-2 justify-content-end">
                <img 
                    className="csv-button" 
                    src={CsvLogo} 
                    title="Download historical replay data as CSV" 
                    onClick={() => this.props?.exportHistoricalReplayClicked()} 
                />
            </div>
        }
    }


    renderTable() {
        if(this.props?.loadingGpsPings === true) {
            return <div className="flex flex-row align-items-center gap-2 p-2">
                <Spinner color="info" />
                <em>Please wait while we retrieve the historical replay data.</em>
            </div>
        } 
        
        else {
            return <DataTable
                value={this.props?.gpsPings} 
                className="p-datatable-sm p-datatable-gridlines p-datatable-striped p-datatable-hover" 
                rowHover={true}
                autoLayout={true}
                scrollable 
                scrollHeight="100%"
                onRowClick={(e) => this.emitRowClicked(e.data)}
                rowClassName={this.generateRowClassName}
            >
                <Column 
                    field="eventDate" header="Date" /* filter={true} filterMatchMode={'contains'} */
                    style={{ width: '85px' }}
                    body={(row, column) => this.formatDate(row.eventDate)}
                ></Column>
                <Column 
                    field="startTime" header="Start Time" /* filter={true} filterMatchMode={'contains'} */
                    style={{ width: '60px' }}
                    body={(row, column) => this.formatTime(row.startTime)}
                ></Column>
                <Column 
                    field="finishTime" header="Finish Time" /* filter={true} filterMatchMode={'contains'} */
                    style={{ width: '60px' }}
                    body={(row, column) => this.formatTime(row.finishTime)}
                ></Column>
                <Column field="jobNumber" header="Job Number" filter={true} filterMatchMode={'contains'} style={{ width: '60px' }}></Column>
                <Column field="ref1" header="Ref 1" filter={true} filterMatchMode={'contains'} style={{ width: '65px' }}></Column>
                <Column field="ref2" header="Ref 2" filter={true} filterMatchMode={'contains'} style={{ width: '65px' }}></Column>
                <Column field="trackingLeg" header="Leg Number" style={{ width: '55px' }}></Column>
                <Column 
                    field="jobEvent" header="Status"
                    body={(row, column) => this.utilsService.jobEventEnumName(row.jobEvent)}
                ></Column>
            </DataTable>
        }
    }
    
    
    render() {
        return <div className="historical-replay-grid flex flex-column gap-1">
            {this.renderTableActions()}
            {this.renderTable()}
        </div>
    }
}