import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Redirect } from 'react-router';

import ManagerContext from '../context/ManagerContext';

export class Login extends Component {
    displayName = Login.name;

    

    handleSubmit = (event) => {
        event.preventDefault();

        var user = btoa(document.getElementById("UserName").value);
        var pass = btoa(document.getElementById("UserPassword").value);

        let context = this.context;
        context.AsnLogin(user,pass);
    }

    componentDidMount() {
        this.checkwebtoken();
    }

    checkwebtoken = () => {
        let context = this.context;

        const urlParamString = window.location.search;
        const urlParams = new URLSearchParams(urlParamString);

        if (urlParams.has("webtoken")) {
            var token = urlParams.get("webtoken");
            context.OscLogin(token);
        }
    }

    render() {
        let context = this.context;

        if (!context.IsLoggedIn()) {
            return (
                <Container>
                    <div className="center-block"><h1>Ilogix Dashboard</h1></div>
                    <div><p>Please Login using the form below:</p></div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="UserName">User Name</label>
                            <input type="text" className="form-control" id="UserName" aria-describedby="emailHelp" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="UserPassword">Password</label>
                            <input type="password" className="form-control" id="UserPassword" />
                        </div>
                        <button type="submit" className="btn btn-primary" >Submit</button>
                    </form>
                </Container>
            );
        } else {
            return (
                <div>
                    <Redirect to="/dashboard" />
                </div>
            );
        }
    }
}

Login.contextType = ManagerContext;
