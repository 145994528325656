import { Component } from 'react';
import './JobProgressIndicator.css';



/**
 * PROPS
 * - progress(a number with value either 1 - 15)
 * 
 * Shows the progress for a specific job.
 * We have 15 slices/stages in this progress indicator.
 * 1 - Allocated
 * 2 - Arrived at Pickup
 * 3 - Picked up
 * 4 - 0%
 * 5 - 10%
 * 6 - 20%
 * 7 - 30%
 * 8 - 40%
 * 9 - 50%
 * 10 - 60%
 * 11 - 70%
 * 12 - 80%
 * 13 - 90%
 * 14 - Arrived at Delivery
 */
export class JobProgressIndicator extends Component {
    constructor(props) {
        super(props);
    }


    renderCells() {
        const cells = [];
        
        for(let i = 1; i <= 14; i++) {
            if(i <= this.props?.progress) {
                cells.push(<td key={i} className="highlighted"></td>);
            } else {
                cells.push(<td key={i}></td>);
            }
        }

        return cells;
    }
    
    
    render() {
        return (
            <div className="JobProgressIndicator">
                <table>
                    <tbody>
                        <tr>
                            {this.renderCells()}
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}