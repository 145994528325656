import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { NavFooter } from './NavFooter';



export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
        <div>
            <NavMenu />
            <div className="spacer-top"></div>

            <div>
              {this.props.children}
            </div>

            <div className="spacer-bottom"></div>
            <NavFooter />
        </div>
    );
  }
}
