import './TogglePanel.css';

import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import { BsSearch, BsX } from "react-icons/bs";

import { RecentCompletedJobs } from '../components/RecentCompletedJobs';
import { GetFilteredData } from '../Service';
import { FilteredResults } from './FilteredResults';

// enum SearchModeEnum {
//     Reference1 = 1;
//     Reference2 = 2;
//     ConsignmentNumber = 3;
//     Barcode = 4;
//     Ref1Ref2ConsignmentNumber = 5;
// }


export class TogglePanel extends Component {
    static displayName = TogglePanel.name;

    constructor(props) {
        super(props);
        this.state = {
            searchInput: '',
            searching: false,
            startDate: new Date(),
            filterMonthRange: 5,
            filterInitialised: false,
            searchMode: 5, /* Either "Ref1Ref2ConsignmentNumber(5)" or "Barcode(4)" */
        };

    }


    searchOptions = [
        { label: 'Quick Search', command: () => this.setState({ searchMode: 5 }) },
        { label: 'Barcode', command: () => this.setState({ searchMode: 4 }) },
    ];


    monthRangeOptions = [
        // { value: 1, display: '30 days' },
        // { value: 2, display: '2 months' },
        // { value: 3, display: '3 months' },
        // { value: 4, display: '4 months' },
        { value: 5, display: '5 months' },
    ];


    get searchModePlaceholder() {
        if(this.state?.searchMode === 5) {
            return 'Search by Consignment or Reference...';
        } else if(this.state?.searchMode === 4) {
            return 'Search by Barcode...'
        } else {
            return '';
        }
    }


    get searchModeDropdownText() {
        if(this.state?.searchMode === 5) {
            return 'Quick Search';
        } else if(this.state?.searchMode === 4) {
            return 'Barcode'
        } else {
            return '';
        }
    }
    

    // Search Button (Also search final trigger)
    handleSearchSubmit = (event) => {
        if (this.state.searchInput.length < 4) {
            alert("Please use a longer search criteria");
            return;
        }

        this.setState({
            filterInitialised: true,
            searching: true
        });    
    };


    // Keystroke monitor
    handleSearchKeydown = (event) => {
        var charCode;

        if (event && event.which) {
            charCode = event.which;
        } else if (window.event) {
            event = window.event;
            charCode = event.keyCode;
        }

        if (charCode === 13) {
            this.handleSearchSubmit();
        }
    };

    // Text Change monitor
    handleSearchChange = (event) => {
        this.setState({
            searchInput: event.target.value,
            filterInitialised: false
        });
    }

    // Clear button
    handleSearchClear = (event) => {
        this.setState({
            searchInput: '',
            filterInitialised: false,
            startDate: new Date()
        });
    }

    updateSearching = (event) => {
        if (event.target.innerText === 'Search Results') {
            if (this.state.searchInput.length >= 4) {
                this.setState({ searching: true });
            }
        } else if (event.target.innerText === 'Recent Completed Jobs') {
            this.setState({ searching: false, filterInitialised: false });
        }
    };


    render() {
        const mainContent = this.state.searching && this.state.filterInitialised
            ? <FilteredResults searchString={this.state.searchInput} searchBy={this.state.searchMode} startDate={this.state.startDate} range={this.state.filterMonthRange}></FilteredResults>
            : <RecentCompletedJobs></RecentCompletedJobs>;
        
        return <div className="flex recent-completed-toggle-panel flex-column gap-3">
            <Menu model={this.searchOptions} popup ref={el => this.searchMenuRef = el} />

            <div className="flex flex-row justify-content-start gap-2">
                <div className="p-inputgroup search-input-container">
                    <Button label={this.searchModeDropdownText} icon="pi pi-angle-down" iconPos="right" onClick={(event) => this.searchMenuRef.toggle(event)} />
                    <InputText placeholder={this.searchModePlaceholder} value={this.state.searchInput} onKeyPress={this.handleSearchKeydown} onChange={this.handleSearchChange} />
                </div>

                <Calendar className="flex-none" value={this.state.startDate} dateFormat="dd-M-y" showIcon onChange={(e) => this.setState({ startDate: e.value })} />

                <div className="p-inputgroup flex flex-row justify-content-end">
                    <Dropdown className="flex-none" value={this.state.filterMonthRange} options={this.monthRangeOptions} onChange={(e) => this.setState({ filterMonthRange: e.value })} optionLabel="display" placeholder="Select a City" />
                    <Button onClick={this.handleSearchSubmit} icon="pi pi-search" />
                    <Button onClick={this.handleSearchClear} icon="pi pi-times" />
                </div>
            </div>

            <div className="modes-indicator flex flex-row" style={{ height: '40px' }}>
                <b className={"btn btn-block " + (!this.state.filterInitialised ? 'btn-primary' : 'btn-light')} onClick={this.updateSearching}>Recent Completed Jobs</b>
                <b className={"btn btn-block " + (this.state.filterInitialised ? 'btn-primary' : 'btn-light disabled')} style={{ marginTop: '0px' }} onClick={this.updateSearching}>Search Result</b>
            </div>

            {mainContent}
        </div>
    }
}