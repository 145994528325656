import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/shared/Layout';

import { DashboardPage } from './pages/DashboardPage/DashboardPage';
import { MapPage } from './pages/MapPage';
import { Login } from './pages/Login';
import { Logout } from './pages/Logout';
import { Landing } from './pages/Landing';
import { Tracking } from './pages/Tracking';
import { Asn } from './pages/Asn';
import { Reports } from './pages/Reports';
import { HistoricalReplayPage } from './pages/HistoricalReplayPage/HistoricalReplayPage';

import { ManagerProvider } from './context/ManagerContext';

import { Toaster } from 'react-hot-toast';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <ManagerProvider>
                <div><Toaster position="bottom-right"
                    reverseOrder={false} /></div>
                <Layout>
                    <Route exact path='/' component={Landing} />
                    <Route path='/dashboard' component={DashboardPage} />
                    <Route path='/map' component={MapPage} />
                    <Route path='/login' component={Login} />
                    <Route path='/logout' component={Logout} />
                    <Route path='/tracking' component={Tracking} />
                    <Route path='/asn' component={Asn} />
                    <Route path='/reports' component={Reports} />
                    <Route path='/historical-replay/:accountCode/:driverNumber/:stateAbbrev/:jobDate' component={HistoricalReplayPage} />
                </Layout>
            </ManagerProvider>
        );
    }
}
