import React, { Component } from 'react';
import {
    Container,
    Button,
    Row,
    Col,
    Input,
    Card, 
    FormGroup, Label, Table,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import ManagerContext from '../../context/ManagerContext';

import { BsPencil, BsBackspace, BsCheck } from "react-icons/bs";
import AsnConnect from '../../module/AsnConnector';


export class JobEdit extends Component {
    static displayName = JobEdit.name;

    constructor(props,context) {
        super(props,context);

        let baseJob = props.job;

        this.state = {
            modal: false,
            job: baseJob,
            advanceDate: baseJob.readyFor,
            advanceTime: baseJob.readyFor,
            sundries: null,
            itemOrig: null,
            items: [],
            newRemark: '',
            remarks: [],
            newItem: {
                itemId: null,
                BookingId: null,
                description: null,
                length: null,
                width: null,
                height: null,
                weight: null,
                cubic: null,
                status: null,
                barcode: null,
                qantity:null
            },
            itemrequest: {
                items: [],
                job: baseJob
            },
            remarkupdaterequest: {
                remarks: [],
                job: baseJob
            }
        };
    }    



    loadExtraData = () => {
        if (this.context.User.allowHds) {
            AsnConnect.JobSundries(this.props.job).then((data) => {
                this.setState({ sundries: data });
            });
        }

        AsnConnect.JobRemarks(this.props.job).then((data) => {
            this.setState({ remarks: data });
        });
        AsnConnect.JobItems(this.props.job).then((data) => {
            this.setState({ items: JSON.parse(JSON.stringify(data)), itemOrig: JSON.parse(JSON.stringify(data)) });
        });
    }

    handleChange = (e) => {
        let vals = this.state.job;
        vals[e.target.dataset.field] = e.target.value.toUpperCase();
        this.setState({ job: vals });
    }

    handleChangeSundry = (e) => {
        let vals = this.state.sundries;

        vals[e.target.dataset.field] = e.target.value.toUpperCase();

        this.setState({ sundries: vals });
    }

    handleChangeItem = (e) => {
        let vals = this.state.newItem;

        vals[e.target.dataset.field] = e.target.value.toUpperCase();
        this.setState({ newItem: vals });
    }

    handleChangeReamrk = (e) => {
        let val = e.target.value;
        this.setState({ newRemark: val });
    }

    clearItem = (idx) => {
        let newItems = this.state.items;
        newItems.splice(idx, 1);
        this.setState({ items: newItems });
    }

    addItem = () => {
        let vals = this.state.items;
        let newItem = JSON.parse(JSON.stringify(this.state.newItem));

        vals.push(newItem);
        this.setState({ items: vals });
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal });
    };

    cancelNow = () => {

        AsnConnect.JobCancel(this.state.job).then((data) => {
            this.toggle();
        });
    }

    bookNow = () => {
        AsnConnect.JobBook(this.state.job).then((data) => {
            this.toggle();
        });
    }

    saveChanges = () => {
        this.setUpdateItemRequest();
        this.setUpdateRemarkRequest();
        AsnConnect.JobItemsSave(this.state.itemrequest);
        AsnConnect.JobRemarksSave(this.state.remarkupdaterequest);

        AsnConnect.JobEdit(this.state.job).then((data) => {
            this.toggle();
        });
    }

    setUpdateItemRequest() {
        let vals = this.state.itemrequest;
        vals['items'] = this.state.items;
        vals['job'] = this.state.job;
        this.setState({ itemrequest: vals });
    }

    setUpdateRemarkRequest() {
        let vals = this.state.remarkupdaterequest;
        vals['remarks'] = this.state.remarks;
        vals['job'] = this.state.job;
        this.setState({ remarkupdaterequest: vals });
    }

    handleReadyNow = () => {
        let vals = this.state.job;
        vals['advanceDate'] = '';
        vals['advanceTime'] = '';
        this.setState({ job: vals });
    }

    addRemark = () => {
        if (this.state.newRemark.length < 5) {
            alert("Remarks need to be longer than 5 characters");
            return;
        }

        let rems = this.state.remarks;

        rems.push({ id: null, bookingId: this.state.job.bookingId, description: JSON.parse(JSON.stringify(this.state.newRemark)) });
        this.setState({ remarks: rems, newRemark: '' });
    }

    render() {
        const stateAbbrev = this.props.stateAbbrev;
        let context = this.context;

        return (

            <Container fluid>
                <Button className="input-fitted" onClick={this.toggle} block color="warning"><BsPencil /></Button>
                <Modal onOpened={this.loadExtraData} isOpen={this.state.modal} toggle={this.toggle} centered size="xl" backdrop="static" fade>
                    <ModalHeader toggle={this.toggle}>Modifying Consignment X{this.state.job.id}</ModalHeader>
                    <ModalBody>
                        <p>Job Details</p>
                        <Card body>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Caller</Label>
                                        <Col sm={8}><Input data-field="caller" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.caller} /></Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Reference 1</Label>
                                        <Col sm={8}><Input data-field="reference1" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.reference1} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Reference 2</Label>
                                        <Col sm={8}><Input data-field="reference2" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.reference2} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Created On</Label>
                                        <Col sm={8}><Input disabled type="text" bsSize="sm" value={this.state.job.bookedOn} /></Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Ready For</Label>
                                        <Col sm={5}><Input data-field="advanceDate" onChange={this.handleChange} value={this.state.job.advanceDate}  type="date" className="input-fitted" size="sm" /></Col>
                                        <Col sm={3}><Input data-field="advanceTime" onChange={this.handleChange} value={this.state.job.advanceTime}  type="time" className="input-fitted" size="sm" /></Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Button size="sm" sm={4} onClick={this.handleReadyNow} outline>Ready Now</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Item Count</Label>
                                        <Col sm={8}><Input disabled type="text" bsSize="sm" value={this.state.job.items} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Service</Label>
                                        <Col sm={8}><Input data-field="service" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.service} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Driver</Label>
                                        <Col sm={8}><Input data-field="driver" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.driver} /></Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Notifications Number</Label>
                                        <Col sm={8}><Input data-field="contactNumber" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.contactNumber} /></Col>
                                    </FormGroup>

                                </Col>                            
                            </Row>
                        </Card>

                        <br />
                        <p>Sender Details</p>
                        <Card body>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Sender Name</Label>
                                        <Col sm={8}><Input data-field="pickupName" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.pickupName} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Address 1</Label>
                                        <Col sm={8}><Input data-field="pickupAddress1" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.pickupAddress1} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Address 2</Label>
                                        <Col sm={8}><Input data-field="pickupAddress2" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.pickupAddress2} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Address 3</Label>
                                        <Col sm={8}><Input data-field="pickupAddress3" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.pickupAddress3} /></Col>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Postcode</Label>
                                        <Col sm={8}><Input data-field="pickupPostcode" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.pickupPostcode} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>State</Label>
                                        <Col sm={8}><Input disabled type="text" bsSize="sm" value={stateAbbrev} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Suburb</Label>
                                        <Col sm={8}><Input data-field="pickupSuburb" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.pickupSuburb} /></Col>
                                    </FormGroup>

                                </Col>
                            </Row>
                                <FormGroup row>
                                    <Label size="sm" sm={2}>Extra Pickup Details</Label>
                                    <Col sm={10}><Input data-field="extraPuInformation" onChange={this.handleChange} type="textarea" style={{ resize: 'none' }} bsSize="sm" value={this.state.job.extraPuInformation} /></Col>
                                </FormGroup>

                        </Card>

                        <br />
                        <p>Receiver Details</p>
                        <Card body>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Receiver Name</Label>
                                        <Col sm={8}><Input data-field="receiverName" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.receiverName} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Address 1</Label>
                                        <Col sm={8}><Input data-field="deliveryAddress1" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.deliveryAddress1} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Address 2</Label>
                                        <Col sm={8}><Input data-field="deliveryAddress2" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.deliveryAddress2} /></Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Address 3</Label>
                                        <Col sm={8}><Input data-field="deliveryAddress3" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.deliveryAddress3} /></Col>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Postcode</Label>
                                        <Col sm={8}><Input data-field="deliveryPostcode" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.deliveryPostcode} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>State</Label>
                                        <Col sm={8}><Input disabled type="text" bsSize="sm" value={stateAbbrev} /></Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label size="sm" sm={4}>Suburb</Label>
                                        <Col sm={8}><Input data-field="deliverySuburb" onChange={this.handleChange} type="text" bsSize="sm" value={this.state.job.deliverySuburb} /></Col>
                                    </FormGroup>

                                </Col>
                            </Row>

                            <FormGroup row>
                                <Label size="sm" sm={2}>Extra Delivery Details</Label>
                                <Col sm={10}><Input data-field="extraDelInformation" onChange={this.handleChange} type="textarea" style={{ resize: 'none' }} bsSize="sm" value={this.state.job.extraDelInformation} /></Col>
                            </FormGroup>
                        </Card>

                        {(context.User.allowHds && this.state.sundries) &&
                        <div>
                            <br />
                            <p>Additional Charges</p>
                            <Card body>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup row>
                                            <Label size="sm" sm={4}>Sundry 1</Label>
                                            <Col sm={3}><Input data-field="qty1" type="text" bsSize="sm" value={this.state.sundries.qty1} onChange={this.handleChangeSundry} /></Col>
                                            <Col sm={5}><Input data-field="service1" type="text" bsSize="sm" value={this.state.sundries.service1} onChange={this.handleChangeSundry}  /></Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label size="sm" sm={4}>Sundry 2</Label>
                                            <Col sm={3}><Input data-field="qty2" type="text" bsSize="sm" value={this.state.sundries.qty2} onChange={this.handleChangeSundry} /></Col>
                                            <Col sm={5}><Input data-field="service2" type="text" bsSize="sm" value={this.state.sundries.service2} onChange={this.handleChangeSundry}/></Col>
                                        </FormGroup>
                                    

                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup row>
                                            <Label size="sm" sm={4}>Sundry 3</Label>
                                            <Col sm={3}><Input data-field="qty3" type="text" bsSize="sm" value={this.state.sundries.qty3} onChange={this.handleChangeSundry} /></Col>
                                            <Col sm={5}><Input data-field="service3" type="text" bsSize="sm" value={this.state.sundries.service3} onChange={this.handleChangeSundry} /></Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label size="sm" sm={4}>Sundry 4</Label>
                                            <Col sm={3}><Input data-field="qty4" type="text" bsSize="sm" value={this.state.sundries.qty4} onChange={this.handleChangeSundry} /></Col>
                                            <Col sm={5}><Input data-field="service4" type="text" bsSize="sm" value={this.state.sundries.service4} onChange={this.handleChangeSundry} /></Col>
                                        </FormGroup>

                                    </Col>
                                </Row>
                            </Card>
                        </div>
                        }

                        
                        <div>
                            <br />
                            <p>Items</p>
                            <Card body>
                                
                                <Table striped hover bordered bsSize="sm">
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>L<sub>&nbsp;cm</sub></th>
                                            <th>W<sub>&nbsp;cm</sub></th>
                                            <th>H<sub>&nbsp;cm</sub></th>
                                            <th>W<sub>&nbsp;kg</sub></th>
                                            <th>C<sup>3</sup></th>
                                            <th>Qty</th>
                                            <th>Barcode</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.items.map((item,idx) => (
                                        <tr key={idx}>
                                            <td>{item.description}</td>
                                            <td>{item.length}</td>
                                            <td>{item.width}</td>
                                            <td>{item.height}</td>
                                            <td>{item.weight}</td>
                                            <td>{item.cubic}</td>
                                            <td>{item.qantity}</td>
                                            <td>{item.barcode}</td>
                                            <td>Received</td>
                                            <td onClick={() => this.clearItem(idx)}><BsBackspace /></td>
                                        </tr>
                                    ))}
                                        <tr>
                                            <td><Input type="text" bsSize="sm" onChange={this.handleChangeItem} data-field="description" value={this.state.newItem.description} /></td>
                                            <td><Input type="text" bsSize="sm" onChange={this.handleChangeItem} data-field="length" value={this.state.newItem.length} /></td>
                                            <td><Input type="text" bsSize="sm" onChange={this.handleChangeItem} data-field="width" value={this.state.newItem.width}/></td>
                                            <td><Input type="text" bsSize="sm" onChange={this.handleChangeItem} data-field="height" value={this.state.newItem.height} /></td>
                                            <td><Input type="text" bsSize="sm" onChange={this.handleChangeItem} data-field="weight" value={this.state.newItem.weight} /></td>
                                            <td><Input type="text" bsSize="sm" onChange={this.handleChangeItem} data-field="cubic" value={this.state.newItem.cubic} /></td>
                                            <td><Input type="text" bsSize="sm" onChange={this.handleChangeItem} data-field="qantity" value={this.state.newItem.qantity} /></td>
                                            <td><Input type="text" bsSize="sm" onChange={this.handleChangeItem} data-field="barcode" value={this.state.newItem.barcode} /></td>
                                            <td><Input type="text" bsSize="sm" onChange={this.handleChangeItem} data-field="status" value={this.state.newItem.status}/></td>
                                            <td onClick={this.addItem}><BsCheck /></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                        

                        {context.User.allowHds  &&
                        <div>
                            <br />
                            <p>Remarks</p>
                            <Card body>
                                

                                <FormGroup row>
                                    <Button bsSize="sm" sm={2} onClick={this.addRemark}>New Remark</Button>
                                    <Col sm={10}><Input onChange={this.handleChangeReamrk} type="textarea" style={{ resize: 'none' }} bsSize="sm" value={this.state.newRemark} /></Col>
                                </FormGroup>

                                <Table><tbody>                                    
                                    {this.state.remarks.map((remark,idx )=> (
                                        <tr key={idx}>
                                            <td>{remark.description}</td>
                                        </tr>
                                    ))}
                                </tbody></Table>
                            </Card>
                        </div>

                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="warning" onClick={this.cancelNow} className={'mr-auto'}>Cancel Job</Button>

                        <Button color="secondary" onClick={this.bookNow}>Book Now</Button>
                        <Button color="primary" onClick={this.saveChanges}>Save Changes</Button>
                        
                    </ModalFooter>
                </Modal>
            </Container>
        );
     
    }
}


JobEdit.contextType = ManagerContext;
