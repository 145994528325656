import React, { Component } from 'react';
import {
    Navbar,
    NavbarText,
    Container
} from 'reactstrap';

import ManagerContext from '../../context/ManagerContext';

const options = {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
};

export class NavFooter extends Component {
    static displayName = NavFooter.name;

    render() {
        let context = this.context;

        var msg = ''
        if (context.IsLoggedIn()) {
            msg = 'Welcome ' + context.User.clientCode;
        } else {
            msg = 'Please Log In'
        }



        var refresh = new Date(context.LastRefresh).toLocaleTimeString("en-US", options);

        return (
        <footer>
            <Navbar style={{ backgroundColor: '#2a2c71' }} dark expand="md" fixed="bottom" className="lowerZ">
                <Container fluid>
                    <NavbarText style={{ float: 'left', color: '#ffffff' }}>iLogix 2024</NavbarText>
                    <NavbarText style={{ float: 'center', color: '#ffffff' }}>{msg}
                    </NavbarText>

                    <NavbarText style={{ float: 'right' }}>
                        {refresh}
                    </NavbarText>
                </Container>
            </Navbar>
            </footer>
        );
  }
}

NavFooter.contextType = ManagerContext;