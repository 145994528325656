import { noConflict } from 'leaflet';
import React, { Component } from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';

import { MapPane } from '../components/MapPane';
import { SingleJobDetail } from '../components/tracking/SingleJobDetail';

import ManagerContext from '../context/ManagerContext';
import Track from '../module/Track';
import { LoadTrackAndTraceData } from '../Service';

export class Tracking extends Component {
    static displayName = Tracking.name;
    trackingDetails
    

    constructor(props, context) {
        super(props, context)
        
        this.state = {
            trackingToken: '',
            trackingJob: null,
            status: 'searching',
            trackingType: 'none'            
        };
    }

    componentDidMount() {
        this.checkParams();
    }

    checkParams = () => {
        const urlParamString = window.location.search;
        const urlParams = new URLSearchParams(urlParamString);

        if (urlParams.has("user")) {

            const payload = {
                token: urlParams.get("token"),
                user: urlParams.get("user"),

                ref: urlParams.get("ref"),
                date: urlParams.get("date"),
                jobNumber: urlParams.get("job")
            };

            Track.GetTrackableJobTT(payload)
                .then((data) => {
                    if (data === 'failure') {
                        this.setState({ status: 'invalid' });
                    }
                    else {
                        this.setState(
                            {
                                status: 'found',
                                trackingToken: window.location.href.split("=")[1],
                                trackingJob: data,
                                trackingType: 'user'
                            });
                    }
                });        

        } else if (urlParams.has("Token") )
        {

            const token = urlParams.get("Token");

            Track.GetTrackableJob(token)
                .then((data) => {

                    if (data === 'failure') {
                        this.setState({ status: 'invalid' });
                    }
                    else {
                        this.setState(
                            {
                                status: 'found',
                                trackingToken: window.location.href.split("=")[1],
                                trackingJob: data,
                                trackingType: 'token'
                            });
                    }
                });

        } else {
            this.setState({ status: 'invalid' });
        }

    }


    openJobInNewTab(jobNumber) {
        LoadTrackAndTraceData(jobNumber, this.context.User, this.state?.trackingJob?.details?.customer, this.state?.trackingJob?.details?.stateId)
        .then((data) => {
            window.open(data, '_blank');
        })
        .catch((error) => { console.error("Failed!", error); });
    }



    render() {
        if (this.state.status === 'found') {
            return (
                <Container fluid>
                    <Row xs="1" sm="1" md="1" lg="2" xl="2">
                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                            <SingleJobDetail trackingDetails={this.state.trackingJob} ></SingleJobDetail>
                        </Col>
                        <Col xs="12" sm="12" md="6" lg="8" xl="8">
                            <div style={{ height: '87vh' }}>
                                <MapPane 
                                    trackingDetails={this.state.trackingJob}
                                    completedJobMarkerClicked={(jobNumber) => this.openJobInNewTab(jobNumber)}
                                    liveJobMarkerClicked={(jobNumber) => this.openJobInNewTab(jobNumber)}
                                ></MapPane>
                            </div>
                        </Col>

                    </Row>
                </Container>);
        } else if (this.state.status === 'searching') {
            return (
                <Container fluid>
                    <Row xs="1" sm="1" md="1" lg="2" xl="2">
                        <div className="flex flex-row align-items-center gap-2 p-2">
                            <Spinner color="info" />
                            <em>Please wait while we check your tracking token...</em>
                        </div>
                    </Row>
                </Container>
            );
        } else {
            return (
                <Container fluid>
                    <Row xs="1" sm="1" md="1" lg="2" xl="2">
                        <p>Invalid tracking link, please check that you have clicked on the original link or copied the url in full.</p>
                    </Row>
                </Container>
            );
        }

    }
}

Tracking.contextType = ManagerContext;
