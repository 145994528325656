import './DashboardPage.css';

import React, { Component } from 'react';

import Common from '../../module/Common';
import ManagerContext from '../../context/ManagerContext';
import { UtilsService } from '../../services/UtilsService';
import { ApiService } from '../../services/ApiService';

import { ActiveJobs } from '../../components/ActiveJobs';
import { ActiveRoutes } from '../../components/ActiveRoutes';
import { MapPane } from '../../components/MapPane';
import { TogglePanel } from '../../components/TogglePanel';



export class DashboardPage extends Component {

    static displayName = DashboardPage.name;
    utilsService = new UtilsService();
    apiService = new ApiService();
    mapPaneRef;
    loadActiveJobsInterval;
    loadActiveRoutesInterval;


    constructor(props, context) {
        super(props, context);

        this.state = {
            activeJobs: [], 
            activeRoutes: [], 
            loadingActiveJobs: true, 
            loadingActiveRoutes: true, 
        };
    }
    

    componentDidMount() {
        this.setState({ loadingActiveJobs: true });
        this.setState({ loadingActiveRoutes: true });
        
        this.loadActiveJobs();
        this.loadActiveJobsWithInterval();

        // Wrap this inside setTimeout because loadActiveRoutes() depends on context.User which loads after mounting the component.
        setTimeout(() => {
            this.loadActiveRoutes();
            this.loadActiveRoutesWithInterval();
        })
    }


    componentWillUnmount() {
        clearInterval(this.loadActiveJobsInterval);
        clearInterval(this.loadActiveRoutesInterval);
    }


    loadActiveJobs() {
        this.apiService.loadActiveJobs()
        .then(activeJobs => {
            this.setState({ activeJobs: activeJobs });
        })
        .finally(() => {
            this.setState({ loadingActiveJobs: false });
        });
    }


    loadActiveRoutes() {
        // Run only for Sigma related accounts.
        if(this.utilsService?.isClientCodeSigmaRelated(this.context?.User?.clientCode)) {
            this.apiService.loadActiveRoutes()
            .then(activeRoutes => {
                this.setState({ activeRoutes: activeRoutes });
            })
            .finally(() => {
                this.setState({ loadingActiveRoutes: false });
            });
        }
    }


    loadActiveJobsWithInterval() {
        this.loadActiveJobsInterval = setInterval(() => {
            ApiService.activeJobs = []; /* Clear first the cached activeJobs data */
            this.loadActiveJobs();
        }, Common.getRefreshTime());
    }


    loadActiveRoutesWithInterval() {
        this.loadActiveRoutesInterval = setInterval(() => {
            ApiService.activeRoutes = []; /* Clear first the cached activeRoutes data */
            this.loadActiveRoutes();
        }, Common.getRefreshTime());
    }
    
    
    recenterMap(lat, lng) {
        this.mapPaneRef.centerMapByCoordinates(lat, lng);
    }


    recenterMapByDriverNumber(driverNumber) {
        this.mapPaneRef.centerMapByDriverNumber(driverNumber);
    }


    /**
     * Render ActiveRoutes if the logged in account is Sigma related.
     * Else we render ActiveJobs
     */
    renderActiveJobs() {
        if(this.utilsService?.isClientCodeSigmaRelated(this.context?.User?.clientCode)) {
            return <ActiveRoutes activeRoutes={this.state?.activeRoutes} loadingActiveRoutes={this.state?.loadingActiveRoutes} activeRouteSelected={(activeRoute) => this.recenterMapByDriverNumber(activeRoute.driver)}></ActiveRoutes>
        } else {
            return <ActiveJobs activeJobs={this.state?.activeJobs} loadingActiveJobs={this.state?.loadingActiveJobs} jobSelected={(job) => this.recenterMap(job?.driverDetail?.latitude, job?.driverDetail?.longitude)}></ActiveJobs>
        }
    }
    
    
    render() {
        let context = this.context;

        if (context.IsLoggedIn) {
            return (
                <div className="dashboard-page flex flex-column gap-5 xl:flex-row xl:gap-2">
                    <div className="active-jobs-container w-full xl:w-6">
                        {this.renderActiveJobs()}
                    </div>

                    <div className="flex flex-column w-full xl:w-6">
                        <div className="map-container">
                            <MapPane ref={(e) => this.mapPaneRef = e} activeJobs={this.state?.activeJobs}></MapPane>
                        </div>

                        <TogglePanel></TogglePanel>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="dashboard-page">
                    Please ensure that you are logged in
                </div>
            );
        }
  }
}

DashboardPage.contextType = ManagerContext;
