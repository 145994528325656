import Authentication from '../module/Authentication';
import Common from '../module/Common';




export class ApiService {
    static activeJobs = [];
    static recentCompletedJobs = [];
    static activeRoutes = [];
    static filteredJobs = [];


    clearCachedData() {
        ApiService.activeJobs = [];
        ApiService.recentCompletedJobs = [];
        ApiService.activeRoutes = [];
        ApiService.filteredJobs = [];
    }


    loadActiveJobs() {
        if(ApiService.activeJobs.length) {
            return Promise.resolve(ApiService.activeJobs);
        }

        const user = Authentication.CheckStoredSession();
        return fetch('api/Jobs/ByAccount', {
            headers: {
                Authorization: 'Bearer ' + user.jwtToken
            }
        })
        .then(response => {
            return response.json();
        })
        .then(activeJobs => {
            ApiService.activeJobs = activeJobs;
            return Promise.resolve(activeJobs);
        })
        .catch((error) => {
            Promise.reject('Could not load active jobs data');
        });
    }


    loadRecentCompletedJobs() {
        if(ApiService.recentCompletedJobs.length) {
            return Promise.resolve(ApiService.recentCompletedJobs);
        }

        const user = Authentication.CheckStoredSession();
        return fetch('api/RecentCompletedJobs/RecentCompleted', {
            headers: {
                Authorization: 'Bearer ' + user.jwtToken
            }
        })
        .then(response => {
            return response.json();
        })
        .then(recentCompletedJobs => {
            ApiService.recentCompletedJobs = recentCompletedJobs;
            return Promise.resolve(recentCompletedJobs);
        })
        .catch((error) => {
            Promise.reject('Could not load recent completed jobs data');
        });
    }


    loadActiveRoutes() {
        if(ApiService.activeRoutes.length) {
            return Promise.resolve(ApiService.activeRoutes);
        }

        const user = Authentication.CheckStoredSession();
        return fetch('api/jobs/ActiveRoutes', {
            headers: {
                Authorization: 'Bearer ' + user.jwtToken
            }
        })
        .then(response => {
            return response.json();
        })
        .then(activeRoutes => {
            ApiService.activeRoutes = activeRoutes;
            return Promise.resolve(activeRoutes);
        })
        .catch((error) => {
            Promise.reject('Could not load active routes data');
        });
    }


    searchJobs(searchString, searchBy, startDate, range) {
        if(ApiService.filteredJobs.length) {
            return Promise.resolve(ApiService.filteredJobs);
        }

        const user = Authentication.CheckStoredSession();
        return fetch('api/Search', {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + user.jwtToken,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                reference: searchString,
                referenceType: searchBy,
                startDate: startDate,
                range: range
            })
        })
        .then(response => {
            return response.json();
        })
        .then(filteredJobs => {
            ApiService.filteredJobs = filteredJobs;
            return Promise.resolve(filteredJobs);
        })
        .catch((error) => {
            Promise.reject('Could not search jobs');
        });
    }
}