import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { LinkContainer } from 'react-router-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Spinner } from 'reactstrap';

import { GetFilteredData, LoadTrackAndTraceDataFromSearch } from '../Service';

import ManagerContext from '../context/ManagerContext';
import Common from '../module/Common';
import { UtilsService } from '../services/UtilsService';
import { ApiService } from '../services/ApiService';

import './FilteredResults.css';


export class FilteredResults extends Component {

    utilsService = new UtilsService();
    apiService = new ApiService();
    filteredJobsTable;
    displayName = FilteredResults.name;
    searchJobsInterval;
    
    
    constructor(props, context) {
        super(props, context);

        this.state = {
            //login: context.User.xCabUser,
            filteredJobs: [],
            columns: this.generateColumns([]),
            loading: true,
            sortField: 'modified',
            sortOrder: -1,
            name: context.User.ClientName,
            oscSearch: Common.getSearchLink(context.User.stateId, context.User.oscToken)
        };
    };


    componentDidMount() {
        this.setState({ loading: true });
        this.searchJobs();

        this.searchJobsInterval = setInterval(() => {
            this.searchJobs();
        }, Common.getRefreshTime());
    }


    componentWillUnmount() {
        clearInterval(this.searchJobsInterval);
    }


    /**
     * If there is no search result, we are showing a message.
     * This getter will return the appropriate text either "reference"(if we are searching by ref or consignment number) or "barcode"(when searching by barcode).
     */
    get searchByNoResultText() {
        if(this.props?.searchBy === 'Ref1Ref2ConsignmentNumber') {
            return 'reference';
        } else if(this.props?.searchBy === 'Barcode') {
            return 'barcode';
        } else {
            return '';
        }
    }


    searchJobs() {
        ApiService.filteredJobs = [];
        this.apiService.searchJobs(this.props?.searchString, this.props?.searchBy, this.props?.startDate, this.props?.range)
        .then(filteredJobs => {
            this.setState({ filteredJobs: filteredJobs, columns: this.generateColumns(filteredJobs) });
            this.loadOscSearchLink();
        })
        .finally(() => {
            this.setState({ loading: false });
        })
    }


    generateColumns(jobs) {
        const showRouteCode = (jobs?.find(job => job.showRouteCode)) ? true : false; /* Consider showRouteCode as true if at least one of the jobs has showRouteCode:true */
        const showReplayLink = (jobs?.find(job => job.showReplay)) ? true : false; /* Display an extra column to contain the "Replay" link. Do no add the extra column if none of the jobs has showReplay: true */
        
        const columns = [
            {
                field: 'clientCode', header: 'Account',
                body: (row) => <span title={row.clientCode}>{row.clientCode}</span> 
            },
            {
                field: 'routeCode', header: 'Route Code', sortable: true, hidden: showRouteCode ? false : true, filter: true,
                body: (row) => <span title={row.routeCode}>{row.routeCode}</span> 
            },
            {
                field: 'dropNumber', header: 'Drop Number', sortable: true, hidden: showRouteCode ? false : true,
                body: (row) => <span title={row.dropNumber}>{row.dropNumber}</span> 
            },
            {
                field: 'driverNumber', header: 'Driver', sortable: true,
                body: (row) => <span title={row.driverNumber}>{row.driverNumber}</span> 
            },
            {
                field: 'driverDetail.driverName', header: 'Driver Name',
                body: (row) => <span title={row.driverDetail?.driverName}>{row.driverDetail?.driverName}</span> 
            },
            {
                field: 'stateAbbrev', header: 'State',
                body: (row) => <span title={row.stateAbbrev}>{row.stateAbbrev}</span> 
            },
            {
                field: 'ref1', header: 'Ref 1', sortable: true,
                body: (row) => <span title={row.ref1}>{row.ref1}</span> 
            },
            {
                field: 'ref2', header: 'Ref 2', sortable: true,
                body: (row) => <span title={row.ref2}>{row.ref2}</span> 
            },
            {
                field: 'shortJobNumber', header: 'Job Number', sortable: true,
                body: (row) => <span title={row.shortJobNumber}>{row.shortJobNumber}</span> 
            },
            {
                field: 'serviceCode', header: 'Service Code', sortable: true,
                body: (row) => <span title={row.serviceCode}>{row.serviceCode}</span> 
            },
            {
                field: 'deliverySuburb', header: 'Deliveries', sortable: true,
                body: (row) => <span title={row.deliverySuburb}>{row.deliverySuburb}</span> 
            },
            {
                field: 'status', header: 'Status',
                body: (row) => <span title={row.status}>{row.status}</span> 
            },
            { 
                field: 'modified', header: 'Time Modified', 
                body: (row, column) => {
                    const formattedDate = this.utilsService.formatDateToDefault(row.modified);
                    return <span title={formattedDate}>{formattedDate}</span>
                }
            },
            { 
                field: '', header: '', 
                hidden: showReplayLink ? false : true,
                body: (row, column) => {
                    if(row.showReplay) {
                        return <div onClick={(e) => e.stopPropagation()}>
                            <LinkContainer to={'/historical-replay/'+row.clientCode+'/'+row.driverNumber+'/'+row.stateAbbrev+'/'+row.modified}>
                                <Button label="Replay" className="p-button-link text-xs" />
                            </LinkContainer>
                        </div>
                    }
                }
            }
        ];
        
        return columns;
    }


    loadOscSearchLink = (event) => {
        var searchDiv = document.querySelector('#SearchTable').parentElement;
        var newDiv = document.createElement('div');
        var spanBeforeAnchor = document.createElement('span');
        var sbaText = document.createTextNode('Only jobs in the last few months are displayed. For wider search go ');
        spanBeforeAnchor.appendChild(sbaText);
        newDiv.appendChild(spanBeforeAnchor);
        var anchor = document.createElement('a');
        var aText = document.createTextNode('here');
        anchor.href = this.state.oscSearch;
        anchor.appendChild(aText);
        newDiv.appendChild(anchor);
        var spanAfterAnchor = document.createElement('span');
        var saaText = document.createTextNode('.');
        spanAfterAnchor.appendChild(saaText);
        newDiv.appendChild(spanAfterAnchor);
        searchDiv.insertAdjacentElement('afterend', newDiv);
        searchDiv.nextElementSibling.nextElementSibling.style.marginTop = '20px';
    }
    

    renderFiltedResults() {
        const tableColumns = this.generateColumns(this.state?.filteredJobs ?? []);
        const viewIsSmallerThanLaptop = window.innerWidth <= 1200;
        let renderMessage = this.context.IsOscUser();
        
        if(this.state?.loading) {
            return <div className="flex flex-row align-items-center gap-2">
                <Spinner color="info" />
                <em>Please Wait...</em>
            </div>
        } else if(this.state?.filteredJobs?.length === 0) {
            return <div>
                <p><em>Search for {this.searchByNoResultText} '{this.props?.searchString}' did not return any results.</em></p>
                {renderMessage && <div><span>Only jobs in the last few months are displayed. For wider search go </span><a href={this.state.oscSearch}>here</a><span>.</span></div>}
            </div>
        } else {
            const filteredColumns = tableColumns?.filter(column => !column.hidden); /* Do not render columns with "hidden" flag */
            
            return <DataTable
                ref={(e) => this.filteredJobsTable = e} 
                value={this.state.filteredJobs} 
                className="p-datatable-sm p-datatable-gridlines p-datatable-striped" 
                paginator 
                rows={10}
                rowsPerPageOptions={[10, 25, 50]}
                sortField={this.state.sortField}
                sortOrder={this.state.sortOrder}
                onRowClick={(e) => this.utilsService.openJobTrackingPageUsingClientCodeAndState(e.data.jobNumber, e.data.clientCode, e.data.stateAbbrev, this.context.User)}
                autoLayout={true}
                scrollable={viewIsSmallerThanLaptop ? false : true}
                scrollHeight={'calc(50vh - 280px)'}
            >
                {filteredColumns?.map((column, index) => {
                    return <Column 
                        key={index} 
                        field={column.field} 
                        header={column.header} 
                        filter={column.filter}
                        filterElement={column.filterElement}
                        filterMatchMode={'contains'}
                        sortable={column.sortable} 
                        body={column.body}
                    ></Column>
                })}
            </DataTable>
        }
    }
    
    
    render() {
        return (
            <div className="filtered-results" style={{ marginTop: 20 }}>
                {this.renderFiltedResults()}
            </div>
        );
    }
}

FilteredResults.contextType = ManagerContext;