import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';

import SmallLogo from '../../assets/iLogix_tiny.png';

import ManagerContext from '../../context/ManagerContext';
import Common from '../../module/Common';


export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

      this.toggleNavbar = this.toggleNavbar.bind(this);

        this.state = {
          collapsed: false
        };
    }

    toggleNavbar() {
        this.setState({
          collapsed: !this.state.collapsed
        });
    }

    render() {
        let context = this.context;

        var brandLink = false;
        var bookingLink = false;

        var isLoggedIn = context.IsLoggedIn();
        var isAsnUser = context.IsAsnUser();

        if (context.User.clientBrand) {
            brandLink = Common.getBrandLink(context.User.clientBrand);
            bookingLink = Common.getBookingLink(context.User.stateId, context.User.oscToken);
        }

    return (
      <header>
            <Navbar style={{ backgroundColor: '#2a2c71' }} dark expand="md" fixed="top">
                <NavbarBrand><img src={SmallLogo} alt="" width="94" height="30" className="d-inline-block align-text-top" /></NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar} />
                <Collapse isOpen={this.state.collapsed} navbar>
                    <Nav className="mr-auto" navbar>
                        {isLoggedIn &&
                            <>
                            <LinkContainer to='/dashboard'><NavItem><NavLink>Dashboard</NavLink></NavItem></LinkContainer>
                            <LinkContainer to='/map'><NavItem><NavLink>Map</NavLink></NavItem></LinkContainer>

                            {isAsnUser &&
                                <LinkContainer to='/asn'><NavItem><NavLink>ASN</NavLink></NavItem></LinkContainer>
                            }

                            {brandLink &&
                                <>
                                    <NavItem><NavLink href={bookingLink}>Bookings</NavLink></NavItem>
                                </>
                            }

                            <LinkContainer to='/logout'><NavItem><NavLink>Logout</NavLink></NavItem></LinkContainer>
                                
                            </>
                        }
                        {!isLoggedIn &&
                            <>
                                <LinkContainer to='/'><NavItem><NavLink>Home</NavLink></NavItem></LinkContainer>
                                <LinkContainer to='/login'><NavItem><NavLink>Login</NavLink></NavItem></LinkContainer>
                            </>
                        }
                    </Nav>
                    <Nav navbar>
                        {brandLink &&
                            <>
                                <NavItem><NavLink href={brandLink.url}>{brandLink.name}</NavLink></NavItem>
                            </>
                        }
                    </Nav>
                </Collapse>
            </Navbar>
      </header>
    );
  }
}



NavMenu.contextType = ManagerContext;