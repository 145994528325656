import './HistoricalReplayMap.css';

import { Component } from "react";
import { Button } from 'primereact/button';
import { Slider } from 'primereact/slider';
import { Spinner } from 'reactstrap';
import L from 'leaflet';
import { TileLayer, Map, Marker, Popup, Polyline, Tooltip } from 'react-leaflet';
import { format } from 'date-fns';

import IconCar from '../../../assets/car.png';
import IconGift from '../../../assets/carton-box-1.png';

export class HistoricalReplayMap extends Component {

    constructor (props) {
        super(props);

        this.state = {
            vehiclePathIndex: 0,
            playbackSpeedMode: 0, /* { Slow: 0, Medium: 1, Fast: 2 } */
        };
    }

    defaultGpsPing = { latitude: -33.884403, longitude: 151.194743 };
    playbackSetInterval; /* This will hold the instance of setInterval used in playback  */
    lastFlyToTime = (new Date()).getTime(); /* The last time(in ms) we call the "flyTo" method. We save that value here because we want to call flyTo at 1 second at max. */
    playingForward = true;
    playingBackwards = false;
    mapRef; /* Reference to <Map> instance */


    componentDidUpdate(prevProps, prevState) {
        if((this.props?.gpsPings?.length && this.props?.loading === false) && (prevProps.loading !== this.props.loading)) {
            this.playForward();
        }
    }


    get tickDuration() {
        return 1000 / this.playbackSpeed;
    }

    get useMockData() {
        return process.env.REACT_APP_HISTORICAL_REPLAY_MOCK_DATA === 'true' ? true : false;
    }

    /**
     * Returns the playbackSpeed number based on plasybackSpeed mode
     */
    get playbackSpeed() {
        if(this.state?.playbackSpeedMode === 0) {
            return 1;
        } else if(this.state?.playbackSpeedMode === 1) {
            return 5;
        } if(this.state?.playbackSpeedMode === 2) {
            return 10;
        }
    }

    get playbackSpeedModeLabel() {
        if(this.state?.playbackSpeedMode === 0) {
            return 'Slow';
        } else if(this.state?.playbackSpeedMode === 1) {
            return 'Medium';
        } if(this.state?.playbackSpeedMode === 2) {
            return 'Fast';
        }
    }


    // Refer to enum JobEvents at the top of this file.
    isVehicleStop(jobEvent) {        
        if([3, 4, 5, 6, 8].includes(jobEvent)) {
            return true;
        } else {
            return false;
        }
    }


    getVehicleStatus(jobEvent) {
        const index = jobEvent ? jobEvent : 0;
        const statuses = ['Job Booked','Job Update', 'Job Allocated', 'Pickup Arrived', 'Pickup Completed', 'Delivery Arrived', 'Delivery Completed', 'Cancelled', 'Completed', 'GPS'];
        return statuses[index];
    }


    recenterMap(lat, lng, zoomLevel) {
        zoomLevel = zoomLevel ?? 16;
        this.pause();
        this.mapRef.leafletElement.flyTo([lat, lng], zoomLevel, { animation: true });
    }


    increaseSpeed() {
        if(this.state?.playbackSpeedMode < 2) {
            const speedMode = this.state.playbackSpeedMode + 1;
            this.setState({ playbackSpeedMode: speedMode });

            setTimeout(() => {
                if(this.playingForward) {
                    this.playForward();
                } else if(this.playingBackwards) {
                    this.playBackwards();
                }
            }, 250)
        }
    }


    decreaseSpeed() {
        if(this.state.playbackSpeedMode > 0) {
            const speedMode = this.state.playbackSpeedMode - 1;
            this.setState({ playbackSpeedMode: speedMode });

            setTimeout(() => {
                if(this.playingForward) {
                    this.playForward();
                } else if(this.playingBackwards) {
                    this.playBackwards();
                }
            }, 250)
        }
    }


    playForward() {
        this.pause();
        this.playingForward = true;
        this.playingBackwards = false;
        
        this.playbackSetInterval = setInterval(() => {
            if(this.state.vehiclePathIndex < this.props?.gpsPings.length - 1) {
                this.setState({ vehiclePathIndex: this.state.vehiclePathIndex+1 });
            } else {
                this.pause();
            }
        }, this.tickDuration);
    }


    playBackwards() {
        this.pause();
        this.playingBackwards = true;
        this.playingForward = false;
        
        this.playbackSetInterval = setInterval(() => {
            if(this.state.vehiclePathIndex > 0) {
                this.setState({ vehiclePathIndex: this.state.vehiclePathIndex-1 });
            } else {
                this.pause();
            }
        }, this.tickDuration);
    }


    pause() {
        if(this.playbackSetInterval) {
            clearInterval(this.playbackSetInterval);
        }
    }


    reset() {
        this.pause();
        this.setState({ vehiclePathIndex: 0 });
    }


    next() {
        this.pause();

        if(this.state.vehiclePathIndex < this.props?.gpsPings.length - 1) {
            this.setState({ vehiclePathIndex: this.state.vehiclePathIndex+1 });
        }
    }


    previous() {
        this.pause();

        if(this.state.vehiclePathIndex > 0) {
            this.setState({ vehiclePathIndex: this.state.vehiclePathIndex-1 });
        }
    }


    openPopup(markerRef) {
        if(markerRef?.leafletElement) {
            markerRef.leafletElement.openPopup();
        }
    }


    /**
     * Focus the map on the provided marker.
     */
    focusMarker(marker) {
        const mapInstance = marker?.leafletElement?._map;
        const markerInstance = marker?.leafletElement;

        if(mapInstance && markerInstance) {
            const currentMapZoom = mapInstance?.getZoom();
            const markerLatLng = markerInstance?.getLatLng();
            const currentTimeInMs = (new Date()).getTime();
            
            // We only want to call "flyTo" method for every 1 second at max.
            // This is useful for highspeed playback as it derails/misaligns the Polyline on the map.
            if((currentTimeInMs - this.lastFlyToTime) >= 1000) {
                mapInstance?.flyTo(markerLatLng, currentMapZoom, { animation: true });
                this.lastFlyToTime = currentTimeInMs;
            }
        }
    }

    
    renderVehicleMarker() {
        const markerCar = new L.Icon({
            iconUrl: IconCar,
            iconSize: [50, 30],
            popupAnchor: [0, -15]
        });

        const gpsPing = this.props?.gpsPings?.length ? this.props?.gpsPings[this.state.vehiclePathIndex] : null; 
        
        if(gpsPing) {
            return <Marker position={[gpsPing.latitude, gpsPing.longitude]} icon={markerCar} ref={(e) => this.focusMarker(e)}>
                <Tooltip direction="bottom" offset={[0, 15]} opacity={1} permanent>
                    #{this.props?.driverNumber}
                </Tooltip>
            </Marker>
        }
    }


    renderVehiclePath() {
        const polylineData = this.props?.gpsPings?.filter((gpsPing, gpsPingIndex) => (gpsPingIndex <= this.state?.vehiclePathIndex));
        const positions = polylineData?.map(data => [data.latitude, data.longitude]);
        return <Polyline positions={positions} color={'#424242'} weight={3}></Polyline>
    }


    renderVehicleStopMarkers() {
        const markerGift = new L.Icon({
            iconUrl: IconGift,
            iconSize: [35, 35]
        });

        const vehicleStops = this.props?.gpsPings.filter((gpsPing, gpsPingIndex) => this.isVehicleStop(gpsPing.jobEvent) && gpsPingIndex <= this.state?.vehiclePathIndex);
        
        return vehicleStops?.map((gpsPing, gpsPingIndex) => {
            return <Marker position={[gpsPing.latitude, gpsPing.longitude]} icon={markerGift} key={gpsPingIndex}>
                <Popup closeButton={false} autoClose={false} closeOnEscapeKey={true} closeOnClick={true}>
                    <div className="marker-popup flex flex-column gap-1">
                        <div className="text-center font-bold">{gpsPing.trackingLeg}</div>
                        <div><span className="font-bold">Status:</span> {this.getVehicleStatus(gpsPing.jobEvent)}</div>
                        <div><span className="font-bold">Time:</span> { format(new Date(gpsPing.eventDateTime), 'HH:mm:ss a') }</div>
                        <div><span className="font-bold">Job Number:</span> {gpsPing.jobNumber}</div>
                        <div><span className="font-bold">Ref1:</span> {gpsPing.ref1}</div>
                        <div><span className="font-bold">Ref2:</span> {gpsPing.ref2}</div>
                        <div><span className="font-bold">{gpsPing.trackingLeg} Address:</span> {gpsPing.address}</div>
                        <div><span className="font-bold">{gpsPing.trackingLeg} Suburb:</span> {gpsPing.suburb}</div>
                    </div>
                </Popup>
            </Marker>
        })
    }
    
    
    /**
     * Do not display the controls if the historical replay data is not yet loaded.
     */
    renderControls() {
        if(this.props?.loading === true) {
            return <div className="floating-map-controls flex flex-row align-items-center">
                <div className="loading-indicator-container">
                    <Spinner color="info" />
                </div>
            </div>
        }

        else {
            return <div className="floating-map-controls flex flex-column gap-2">
                <div className="speed-controls flex flex-row align-items-center">
                    <Button 
                        className="p-button-icon-only p-button-text p-button-rounded" icon="pi pi-angle-double-left" title="Decrease speed" 
                        onClick={() => this.decreaseSpeed()}
                    />

                    <div className="speed-mode-label text-sm">{this.playbackSpeedModeLabel}</div>
                    
                    <Button 
                        className="p-button-icon-only p-button-text p-button-rounded" icon="pi pi-angle-double-right" title="Increase Speed" 
                        onClick={() => this.increaseSpeed()}
                    />
                </div>

                <div className="playback-controls flex flex-row align-items-center gap-1">
                    <Button className="p-button-icon-only p-button-text p-button-outlined p-button-rounded" icon="pi pi-step-backward-alt" title="Previous" onClick={() => this.previous()} />
                    <Button className="p-button-icon-only p-button-text p-button-outlined p-button-rounded" icon="pi pi-caret-left" title="Play backwards" onClick={() => this.playBackwards()} />
                    <Button className="p-button-icon-only p-button-text p-button-outlined p-button-rounded" icon="pi pi-pause" title="Pause" onClick={() => this.pause()} />
                    <Button className="p-button-icon-only p-button-text p-button-outlined p-button-rounded" icon="pi pi-replay" title="Reset" onClick={() => this.reset()} />
                    <Button className="p-button-icon-only p-button-text p-button-outlined p-button-rounded" icon="pi pi-caret-right" title="Play" onClick={() => this.playForward()} />
                    <Button className="p-button-icon-only p-button-text p-button-outlined p-button-rounded" icon="pi pi-step-forward-alt" title="Next" onClick={() => this.next()} />
                </div>

                <div className="slider-container">
                    <Slider value={this.state.vehiclePathIndex} min={0} max={this.props?.gpsPings?.length-1} onChange={(e) => this.setState({ vehiclePathIndex: e.value })} />
                </div>
            </div>
        }
    }
    
    
    render() {
        return <div className="historical-replay-map">
            <Map ref={(e) => this.mapRef = e} center={[this.defaultGpsPing.latitude, this.defaultGpsPing.longitude]} zoom={13} maxZoom={20} maxNativeZoom={20} zoomControl={false}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={20} maxNativeZoom={20} />
                {this.renderVehicleMarker()}
                {this.renderVehiclePath()}
                {this.renderVehicleStopMarkers()}
            </Map>

            {this.renderControls()}
        </div>;
    }

}

