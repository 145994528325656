import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import ManagerContext from '../context/ManagerContext';
import { ApiService } from '../services/ApiService';
import { MapPane } from '../components/MapPane';
import Common from '../module/Common';


export class MapPage extends Component {
    
    static displayName = MapPage.name;
    apiService = new ApiService();


    constructor() {
        super();
        
        this.state = {
            activeJobs: [],
            loadingActiveJobs: true, 
        };
    }


    componentDidMount() {
        this.setState({ loadingActiveJobs: true });
        
        this.loadActiveJobs();
        this.loadActiveJobsWithInterval();
    }


    loadActiveJobs() {
        this.apiService.loadActiveJobs()
        .then(activeJobs => {
            this.setState({ activeJobs: activeJobs });
        })
        .finally(() => {
            this.setState({ loadingActiveJobs: false });
        });
    }


    loadActiveJobsWithInterval() {
        this.loadActiveJobsInterval = setInterval(() => {
            ApiService.activeJobs = []; /* Clear first the cached activeJobs data */
            this.loadActiveJobs();
        }, Common.getRefreshTime());
    }
    

    render() {
        let context = this.context;

        if (context.IsLoggedIn()) {
            return (
                <Container fluid>
                    <Row xs="1" >
                        <Col style={{height:'75vh'}}>
                            <MapPane activeJobs={this.state?.activeJobs} loadingActiveJobs={this.state?.loadingActiveJobs}></MapPane>
                        </Col>
                    </Row>
                </Container>
            );
        } else {
            return (
                <Container fluid>
                    <p>Please ensure you have logged in</p>
                </Container>
                );
        }

    }
}

MapPage.contextType = ManagerContext;
