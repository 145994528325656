import React, { Component } from 'react';
import { Redirect } from 'react-router';

import ManagerContext from '../context/ManagerContext';

export class Logout extends Component {
    displayName = Logout.name;



    handleSubmit = (event) => {
        event.preventDefault();

        var user = btoa(document.getElementById("UserName").value);
        var pass = btoa(document.getElementById("UserPassword").value);

        let context = this.context;
        context.AsnLogin(user,pass);
    }

    componentDidMount() {
        this.checkwebtoken();
    }

    checkwebtoken = () => {
        if (window.location.href.indexOf("webtoken") > -1) {

            var splitUrl = window.location.href.split("=");
            if (splitUrl.length > 1) {
                var token = window.location.href.split("=")[1];
                let context = this.context;
                context.OscLogin(token);
            }
        }
    }

    render() {
        let context = this.context;

        if (!context.IsLoggedIn()) {
            return (
                <div>
                    <Redirect to="/login" />
                </div>
            );
        } else {
            return (
                <div>
                    <div className="center-block"><h1>Ilogix Dashboard</h1></div>
                    <p>Are you sure you want to logout?
                        Yes: Click on below "Logout" button</p>
                    <button className="btn btn-primary" onClick={context.LogOut}>Logout</button>
                </div>
            );
        }
    }
}

Logout.contextType = ManagerContext;
