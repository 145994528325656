import { format } from 'date-fns';


export class HistoricalReplayApi {

    /**
     * Download a csv file containing the historicalReplay data.
     * @param {*} historicalReplayGpsPings This is usually the set of GPS pings loaded when we open the HistoricalReplayPage.
     */
    exportHistoricalReplayCsv(historicalReplayGpsPings, date, driverNumber) {
        return fetch('api/HistoricReplay/ExportHistoricReplayCsv', {
            method: "POST",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                DriverTrackingEvents: historicalReplayGpsPings
            })
        })
        .then(response => {
            return response.text()
        })
        .then(csvData => {
            // Create a Blob from the CSV string
            const blob = new Blob([csvData], { type: 'text/csv' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a link element
            const downloadLink = document.createElement('a');
            downloadLink.style.display = 'none';
            downloadLink.href = url;

            // Set the download attribute with a filename
            downloadLink.download = `${driverNumber}_${format(new Date(date), 'dd_MMM_yyyy_HH_mm_ss')}.csv`;

            // Append the link to the body
            document.body.appendChild(downloadLink);

            // Programmatically click the link to trigger the download
            downloadLink.click();

            // Clean up
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(url);
        })
    }
}