import React, { Component } from 'react';
import Authentication, { UserClass } from '../module/Authentication';
import Common from '../module/Common';

import { Toaster, toast } from 'react-hot-toast';

import { RefreshData } from '../Service';
import { ApiService } from '../services/ApiService';
const ManagerContext = React.createContext();

const Recievers = [];
var Timer = null;

export class ManagerProvider extends Component {

    apiService = new ApiService();
    
    state = {
        User: new UserClass(),
        Tick: 1,
        LastRefresh: Date.now()
    };

    AsnLogin = (username, password) => {
        Authentication.AsnLogin(username, password)
            .then((data) => {
                this.setState({ User: data });
                this.LoginAlert();
                setInterval(this.RefreshAlert, Common.getRefreshTime());
                this.RefreshAlert();
            }).catch((data) => {
                this.ToastErr("Login Failed");
            });
    }

    OscLogin = (oscToken) => {
        Authentication.OscLogin(oscToken)
            .then((data) => {
                this.setState({ User: data });
                this.LoginAlert();
                setInterval(this.RefreshAlert, Common.getRefreshTime());
                this.RefreshAlert();
            })
    }

    IsOscUser = () => {
        var ret = false

        if (this.state.User.oscToken?.length > 5) {
            ret = true;
        } 

        return ret;
    }

    IsAsnUser = () => {
        return Authentication.IsAsnUser();
    }

    LocalSessionCheck = () => {
        Authentication.CheckStoredSession();

        if (Authentication.IsLoggedIn()) {
            this.setState({ User: Authentication.User });
            this.LoginAlert();
            setInterval(this.RefreshAlert, Common.getRefreshTime());
            this.RefreshAlert();
        }
    }

    LogOut = () => {
        Authentication.LogOut(() => {
            // Clear cached data
            this.apiService.clearCachedData();
            
            // Redirect to bookings if the user was logged in from an external booking page, redirect him to that booking page when he logged out.
            if(this.state?.User?.clientBrand) {
                const bookingLink = Common.getBookingLink(this.state?.User?.stateId, this.state?.User?.oscToken);
                window.location.href = bookingLink;
            } else {
                this.setState({ User: new UserClass() });
                this.LogoutAlert();
                clearInterval(Timer);
            }

        });
    }

    IsLoggedIn = () => {
        return Authentication.IsLoggedIn();
    }

    LogoutAlert = () => {
        Recievers.forEach(r => {
            r('Logout');
        });  
    }

    LoginAlert = () => {
        Recievers.forEach(r => {
            r('Login');
        });
    }

    RefreshAlert = () => {
        Recievers.forEach(r => {
            r('Refresh');
        });

        this.setState({ LastRefresh: Date.now() });
        console.log('Manager Refresh Signal');
    }

    AllowedClients = () => {
        return Authentication.GetAllowedClients();
    }

    AllowedStates = () => {
        let vic, nsw, sa, wa, qld = 0;
        let states = [];

        let clients = Authentication.GetAllowedClients();

        clients.forEach(function (client) {
            switch (client.stateId)
            {
                case 1:
                    if (!vic) { states.push('VIC'); }
                    vic = 1;
                    break;
                case 2:
                    if (!nsw) { states.push('NSW'); }
                    nsw = 1;
                    break;
                case 3:
                    if (!qld ) { states.push('QLD'); }
                    qld = 1;
                    break;
                case 4:
                    if (!sa) { states.push('SA'); }
                    sa = 1;
                    break;
                case 5:
                    if (!wa) { states.push('WA'); }
                    wa = 1;
                    break;
                default:
                    break;
            }
        });

        return states;
    }

    ToastInf = (message) => {
        toast(message);
    }

    ToastErr = (message) => {
        toast.error(message);
    }

    componentDidMount() {
        /*
         * Signals:
         * 'Logout'
         * 'Login'
         * 'Refresh' */
        Recievers.push(RefreshData);

        this.setState({ User: new UserClass(), LastRefresh: Date.now() });
        this.LocalSessionCheck();
    }


    render() {
       
        const { User, LastRefresh } = this.state;
        const { IsOscUser, IsAsnUser, AsnLogin, OscLogin, LogOut, IsLoggedIn, AllowedClients, AllowedStates, ToastErr, ToastInf } = this;
        
        return (
            <ManagerContext.Provider value={{
                User,
                IsOscUser,
                IsAsnUser,
                IsLoggedIn,
                AsnLogin,
                OscLogin,
                LogOut,
                LastRefresh,
                AllowedClients,
                AllowedStates,
                ToastErr,
                ToastInf
            }}>
                {this.props.children}
            </ManagerContext.Provider>
        )
    }
}

export default ManagerContext;
