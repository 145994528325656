import Authentication from '../module/Authentication';

export default class AsnConnect {

    static JobList = (clientCode, stateId, dateFrom, dateTo, reference1) => {

        return fetch('api/v1/asn/jobs/byClient', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Authentication.User.jwtToken
            },
            body: JSON.stringify({
                'clientCode': clientCode,
                'clientState':stateId,
                'fetchDateFrom':dateFrom,
                'fetchDateTo': dateTo,
                'reference1': reference1
            })
        })
            .then(this.handleErrors)
            .then(data => {
                return data;
            });
    }

    static JobEdit = (job) => {
        if (job.driver == '') {
            job.driver = null;
        };
        return fetch('api/v1/asn/job/edit', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Authentication.User.jwtToken
            },
            body: JSON.stringify(job)
        })
            .then(this.handleErrors)
            .then(data => {
                return data;
            });
    }

    static JobBook = (job) => {
        if (job.driver == '') {
            job.driver = null;
        };

        return fetch('api/v1/asn/job/book', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Authentication.User.jwtToken
            },
            body: JSON.stringify(job)
        })
            .then(this.handleErrors)
            .then(data => {
                return data;
            });
    }


    static JobRemarks = (job) => {

        return fetch('api/v1/asn/job/remarks', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Authentication.User.jwtToken
            },
            body: JSON.stringify(job)
        })
            .then(this.handleErrors)
            .then(data => {
                console.log(data);
                return data;
            });
    }

    static JobRemarksSave = (remarkupdaterequest) => {

        return fetch('api/v1/asn/job/remarks/save', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Authentication.User.jwtToken
            },
            body: JSON.stringify(remarkupdaterequest)
        })
            .then(this.handleErrors)
            .then(data => {
                console.log(data);
                return data;
            });
    }

    static JobItems = (job) => {

        return fetch('api/v1/asn/job/items', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Authentication.User.jwtToken
            },
            body: JSON.stringify(job)
        })
            .then(this.handleErrors)
            .then(data => {
                console.log(data);
                return data;
            });
    }

    static JobItemsSave = (itemrequest) => {

        return fetch('api/v1/asn/job/items/save', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Authentication.User.jwtToken
            },
            body: JSON.stringify(itemrequest)
        })
            .then(this.handleErrors)
            .then(data => {
                console.log(data);
                return data;
            });
    }

    static JobSundries = (job) => {

        return fetch('api/v1/asn/job/sundries', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Authentication.User.jwtToken
            },
            body: JSON.stringify(job)
        })
            .then(this.handleErrors)
            .then(data => {
                console.log(data);
                return data;
            });
    }

    static JobCancel = (job) => {

        return fetch('api/v1/asn/job/cancel', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + Authentication.User.jwtToken
            },
            body: JSON.stringify(job)
        })
            .then(this.handleErrors)
            .then(data => {
                console.log(data);
                return data;
            });
    }

    static handleErrors = (response) => {
        console.log(response);
        if (response.status === 200) {
            return response.json();
        }
        return 'failure';
    }

}

