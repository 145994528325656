import './HistoricalReplayPage.css';

import { Component } from "react";

import { UtilsService } from '../../services/UtilsService';
import { JobApi } from '../../api/JobApi';
import { HistoricalReplayApi } from '../../api/HistoricalReplayApi';
import { HistoricalReplayMap } from './HistoricalReplayMap/HistoricalReplayMap';
import { HistoricalReplayGrid } from './HistoricalReplayGrid/HistoricalReplayGrid';


export class HistoricalReplayPage extends Component {

    constructor (props) {
        super(props);

        this.state = {
            vehiclePathIndex: 0,
            playbackSpeed: 1,
            loadingGpsPings: false,
            gpsPings: []
        };
    }


    utilsService = new UtilsService();
    jobApi = new JobApi();
    historicalReplayApi = new HistoricalReplayApi();
    historicalReplayMapRef;

    accountCode;
    driverNumber;
    stateAbbrev;
    jobDate;


    componentDidMount() {
        this.getUrlParams();
        this.loadGpsPings();
    }


    get tickDuration() {
        return 1000 / this.state.playbackSpeed;
    }

    get useMockData() {
        return process.env.REACT_APP_HISTORICAL_REPLAY_MOCK_DATA === 'true' ? true : false;
    }


    getUrlParams() {
        this.accountCode = this.props?.match?.params?.accountCode ?? '';    
        this.driverNumber = this.props?.match?.params?.driverNumber ?? '';    
        this.stateAbbrev = this.props?.match?.params?.stateAbbrev ?? '';    
        this.jobDate = this.props?.match?.params?.jobDate ?? '';    
    }


    loadGpsPings() {
        this.setState({ loadingGpsPings: true });
        
        this.jobApi.loadJobGpsPings(this.driverNumber, this.jobDate, this.accountCode, this.utilsService.stateAbbrevToStateId(this.stateAbbrev))
        .then(gpsPings => {
            this.setState({ loadingGpsPings: false, gpsPings: this.filterOutInvalidGpsPings(gpsPings) });
        })
    }


    // Returns a list of GpsPing with items with 0latLng value removed.
    filterOutInvalidGpsPings(gpsPings) {
        const filtered = gpsPings.filter(gpsPing => {
            const parsedLat = parseFloat(gpsPing.latitude) ?? 0;
            const parsedLng = parseFloat(gpsPing.longitude) ?? 0;
            
            if(!parsedLat && !parsedLng) {
                return false;
            } else {
                return true;
            }
        });

        return filtered;
    }


    exportHistoricalReplayCsv() {
        this.historicalReplayApi.exportHistoricalReplayCsv(this.state?.gpsPings, this.jobDate, this.driverNumber);
    }


    render() {
        return <div className="historical-replay-page flex flex-column gap-2 xl:flex-row">
            <HistoricalReplayMap 
                ref={(e) => this.historicalReplayMapRef = e}
                gpsPings={this.state?.gpsPings}
                driverNumber={this.driverNumber}
                loading={this.state?.loadingGpsPings}
            ></HistoricalReplayMap>

            <HistoricalReplayGrid 
                gpsPings={this.state?.gpsPings}
                loadingGpsPings={this.state?.loadingGpsPings}
                rowClicked={(gpsPing) => this.historicalReplayMapRef.recenterMap(gpsPing.latitude, gpsPing.longitude, 19)}
                exportHistoricalReplayClicked={() => this.exportHistoricalReplayCsv()}
            ></HistoricalReplayGrid>
        </div>;
    }

}

