import { set, addSeconds } from 'date-fns';
import { random } from 'lodash';



export class JobApi {

    /**
     * CONSIDER renaming this method to "loadHistoricalReplayData" and move it to HistoricalReplayApi.js
     */
    loadJobGpsPings(driverNumber, jobDate, accountCode, stateId) {
        return fetch('api/HistoricReplay', {
            method: "POST",
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                'DriverNumber': driverNumber,
                'Date': jobDate,
                'AccountCode': accountCode,
                'State': stateId
            })
        })
        .then(response => {
            return response.json();
        })
        .then(jsonResponse => {
            const gpsPings = jsonResponse?.driverTrackingEvents ?? [];
            return gpsPings;
        })
        .catch(error => {
            console.error('Could not load GPS pings.', error);
        });
    }
    
    
    /**
     * Returns a list of random generated GPS pings.
     * Starts somewhere in Sydney.
     */
    mockJobGpsPings() {
        const initialGpsPing = {
            latitude: -33.884403,
            longitude: 151.194743,
            eventDateTime: set(new Date(), { hours: 8, minutes: 0, seconds: 0 }),
            jobEvent: 8,
            jobNumber: 167685,
            address: 'Sigma Kemps Creek 2 Imperata Close',
            suburb: 'KEMPS CREEK',
            ref1: 'Test Ref1',
            ref2: 'Test Ref2',
            trackingLeg: 'Pickup'
        };

        let gpsPing = initialGpsPing;
        const numberOfData = 2000;
        const data = [];
        
        for(let x = 0; x < numberOfData; x++) {
            const random1 = random(-0.0003, 0.0003); /* Value to be added to the next location's lat */
            const random2 = random(0, 0.0003); /* Value to be subtracted(we want to go left) to the next location's lng */
            const jobEvent = (random(1, 500) === 4) ? 3 : 9; /* 0.25% chance of having a stop. 3 is PickupArrive while 9 is Gps, 4 is PickupCompleted */

            const newData = {
                ...gpsPing,
                latitude: gpsPing.latitude + random1,
                longitude: gpsPing.longitude - random2,
                jobEvent: jobEvent,
                eventDateTime: addSeconds(gpsPing.eventDateTime, 10)
            };
            
            data.push(newData);
            gpsPing = newData;
        }

        return data;
    }
    
}