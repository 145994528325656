import { format } from 'date-fns';


export default class Common {

    static getRefreshTime = () => {
        return (RefreshSeconds * 1000);
    }

    static getBrandLink = (brand) => {
        var url = 'https://';
        var brandNice = ''
        switch (brand.toUpperCase()) {
            case 'CAPITAL':
            case 'CAPITAL-TRANSPORT':
            case 'NEW-CAPITAL':
                url = url + 'capitaltransport.com.au/';
                brandNice = 'Capital Transport';
                break;
            case 'ADVANCE':
                url = url + 'advancetransport.com.au/';
                brandNice = 'Advance Transport';
                break;
            case 'COMET':
                url = url + 'comettransport.com.au/';
                brandNice = 'Comet Transport';
                break;
            case 'KINGS':
                url = url + 'kingstransport.com.au/';
                brandNice = 'Kings Transport';
                break;
            default:
                url = '';
                brandNice = '';
                break;
        }

        return {url: url, name: brandNice};
    }

    static getBookingLink = (stateId, oscToken) => {
        var url = '';
        switch (stateId) {
            case 1:
                url = "https://mel-bookings.internetcouriers.com.au/other-cgi/jobs_client?webtoken=";
                break;
            case 2:
                url = "https://capsyd-bookings.internetcouriers.com.au/other-cgi/jobs_client?webtoken=";
                break;
            case 3:
                url = "https://bne-bookings.internetcouriers.com.au/other-cgi/jobs_client?webtoken=";
                break;
            case 4:
                url = "https://adl-bookings.internetcouriers.com.au/other-cgi/jobs_client?webtoken=";
                break;
            case 5:
                url = "https://wa-bookings.internetcouriers.com.au/other-cgi/jobs_client?webtoken=";
                break;
            default:
                url = "https://mel-bookings.internetcouriers.com.au/other-cgi/jobs_client?webtoken=";
                break;
        }

        return url + oscToken;
    }

    static getSearchLink = (stateId, oscToken) => {
        var url = '';
        switch (stateId) {
            case 1:
                url = "https://mel-bookings.internetcouriers.com.au/other-cgi/jobs_enquiry_emit?webtoken=";
                break;
            case 2:
                url = "https://capsyd-bookings.internetcouriers.com.au/other-cgi/jobs_enquiry_emit?webtoken=";
                break;
            case 3:
                url = "https://bne-bookings.internetcouriers.com.au/other-cgi/jobs_enquiry_emit?webtoken=";
                break;
            case 4:
                url = "https://adl-bookings.internetcouriers.com.au/other-cgi/jobs_enquiry_emit?webtoken=";
                break;
            case 5:
                url = "https://wa-bookings.internetcouriers.com.au/other-cgi/jobs_enquiry_emit?webtoken=";
                break;
            default:
                url = "https://mel-bookings.internetcouriers.com.au/other-cgi/jobs_enquiry_emit?webtoken=";
                break;
        }
        return url + oscToken;
    }


    /**
     * Format the provided date into our "default human readable" format which looks like "2022 Dec 12 12:04:10"
     * @param date Date
     * @param haveTime whether we show the time or not
     * NOTE: the provided date might be a string date so we make sure to convert it first to Date object.
     * NOTE: Not sure if this file should contain util services or should we create a new "utils.js" file
     */
    static defaultDateFormat(date, haveTime = true, timeOnly = false) {
        const dateObject = new Date(date);

        if(timeOnly) {
            return format(dateObject, 'HH:mm:ss');
        } else if(haveTime) {
            return format(dateObject, 'yyyy MMM dd HH:mm:ss');
        } else {
            return format(dateObject, 'yyyy MMM dd');
        }
    }

}

const RefreshSeconds = 120;

export const StateId = {
    VIC: 1,
    NSW: 2,
    QLD: 3,
    SA: 4,
    WA: 5
}