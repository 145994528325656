import './ActiveRoutes.css';

import React from 'react';
import { Input, Spinner } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { isEqual } from 'lodash';

import ManagerContext from '../context/ManagerContext';
import { UtilsService } from '../services/UtilsService';


// interface ActiveRoutesProps {
//     activeRoutes: ActiveRoute[];
//     loadingActiveRoutes: boolean;
//     activeRouteSelected: ActiveRoute;
// }



export class ActiveRoutes extends React.Component {

    utilsService = new UtilsService();
    loadActiveRoutesInterval;
    
    
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            context: context,
            columns: [],
            expandedRows: [],
            sortField: 'routeCode',
            sortOrder: -1
        };
    }


    toggleExpansionRow(activeRoute) {
        if(this.state?.expandedRows?.length && this.state?.expandedRows[0] == activeRoute) {
            this.setState({ expandedRows: [] });
        } else {
            this.setState({ expandedRows: [activeRoute] });
        }
    }


    activeRouteRowExpandIcon(activeRoute) {
        if(isEqual(activeRoute, this.state?.expandedRows?.at(0))) {
            return <i className="pi pi-angle-up"></i>
        } else {
            return <i className="pi pi-angle-down"></i>
        }
    }


    /**
     * Driver column is using custom filter function.
     * Run this filter whenever the user typed into the Driver column filter.
     * Also sort the active routes by Driver column at the same time.
     */
    filterByDriverNumber(filterValue) {
        this.activeRoutesTable.filter(filterValue, 'driver', 'contains');
        this.activeRoutesTable.setState({ sortField: 'driver', sortOrder: 1 });
        this.setState({ sortField: 'driver', sortOrder: 1 });
    }


    /**
     * Emit "activeRouteSelected" event and pass the driver details.
     * Execute only when the user pressed Enter key while inside the "Driver" column's text filter.
     * If there are multiple drivers in the filtered results, we pick the first driver and pass that in the "activeRouteSelected" event.
     */
    selectDriver(keydownEvent) {
        // Execute only on pressing "Enter" key
        if(keydownEvent.keyCode === 13) { 
            const filters = this.activeRoutesTable.getFilters();
            const results = this.activeRoutesTable?.filterLocal(this.props.activeRoutes, filters);
            const sortedResults = this.activeRoutesTable?.sortSingle(results, 'driver', 1);
            const activeRoute = sortedResults[0];
            this.props?.activeRouteSelected(activeRoute);
        }
    }


    generateColumns(activeRoutes) {
        // const showReplayLink = (activeRoutes?.find(item => item.showReplay)) ? true : false; /* Display an extra column to contain the "Replay" link. Do no add the extra column if none of the items has showReplay: true */
        
        const columns = [
            {
                field: 'routeCode', header: 'Run', sortable: true, filter: true,
                body: (activeRoute, column) => <div className="run-number flex flex-row gap-2 align-items-center" title="Click to see more data" onClick={() => this.toggleExpansionRow(activeRoute)}>
                    {this.activeRouteRowExpandIcon(activeRoute)}
                    <span>{activeRoute.routeCode}</span>
                </div>
            },
            { 
                field: 'branch', header: 'Branch', sortable: true,
                body: (row) => <span title={row.branch}>{row.branch}</span> 
            },
            { 
                field: 'driver', header: 'Driver', sortable: true, filter: true,
                filterElement: <Input onKeyDown={(e) => this.selectDriver(e)} onChange={(e) => this.filterByDriverNumber(e.target?.value)} />,
            },
            {
                field: 'lastDrop', header: 'Last Drop', sortable: true,
                body: (row) => <span title={row.lastDrop}>{row.lastDrop}</span> 
            },
            {
                field: 'timeOnly', header: 'Time', sortable: true,
                body: (row) => <span title={row.timeOnly}>{row.timeOnly}</span> 
            },
            {
                field: 'nextDrop', header: 'Next Drop', sortable: true,
                body: (row) => <span title={row.nextDrop}>{row.nextDrop}</span> 
            },
            {
                field: 'nextDropAddress', header: 'Next Drop Address', sortable: true,
                body: (row) => <span title={row.nextDropAddress}>{row.nextDropAddress}</span> 
            },
            {
                field: 'nextDropSuburb', header: 'Next Drop Suburb', sortable: true,
                body: (row) => <span title={row.nextDropSuburb}>{row.nextDropSuburb}</span> 
            },
            {
                field: 'pending', header: 'Pending', sortable: true,
                body: (row) => <span title={row.pending}>{row.pending}</span> 
            },
            {
                field: 'status', header: 'Status', sortable: true,
                body: (row) => <span title={row.status}>{row.status}</span> 
            },
            { 
                field: 'statusTime', header: 'Status Time', sortable: true,
                body: (row, column) => {
                    const formattedDate = this.utilsService.formatDateToDefault(row.statusTime);
                    return <span title={formattedDate}>{formattedDate}</span>
                }
            },
            // { 
            //     field: '', header: '', 
            //     hidden: showReplayLink ? false : true,
            //     body: (row, column) => {
            //         if(row.showReplay) {
            //             return <div onClick={(e) => e.stopPropagation()}>
            //                 <LinkContainer to={'/historical-replay/'+this.context?.User?.clientCode+'/'+row.driver+'/'+this.context?.User?.stateId+'/'+row.statusTime}>
            //                     <Button label="Replay" className="p-button-link text-xs" />
            //                 </LinkContainer>
            //             </div>
            //         }
            //     }
            // }
        ];

        return columns;
    }


    generateDrilldownColumns(orderedJobs) {
        const showReplayLink = (orderedJobs?.find(orderedJob => orderedJob.showReplay)) ? true : false; /* Display an extra column to contain the "Replay" link. Do no add the extra column if none of the items has showReplay: true */

        const columns = [
            {
                field: 'drop', header: 'Drop',
                body: (row) => <span title={row.drop}>{row.drop}</span> 
            },
            {
                field: 'customer', header: 'Customer',
                body: (row) => <span title={row.customer}>{row.customer}</span> 
            },
            {
                field: 'suburb', header: 'Suburb',
                body: (row) => <span title={row.suburb}>{row.suburb}</span> 
            },
            {
                field: 'jobNumber', header: 'JobNumber',
                body: (row) => <span title={row.jobNumber}>{row.jobNumber}</span> 
            },
            {
                field: 'serviceCode', header: 'Service Code', sortable: true,
                body: (row) => <span title={row.serviceCode}>{row.serviceCode}</span> 
            },
            {
                field: 'ref1', header: 'Ref1', filter: true,
                body: (row) => <span title={row.ref1}>{row.ref1}</span> 
            },
            {
                field: 'type', header: 'Type',
                body: (row) => <span title={row.type}>{row.type}</span> 
            },
            {
                field: 'totes', header: 'Totes',
                body: (row) => <span title={row.totes}>{row.totes}</span> 
            },
            {
                field: 'cases', header: 'Cases',
                body: (row) => <span title={row.cases}>{row.cases}</span> 
            },
            {
                field: 'restr', header: 'Restr',
                body: (row) => <span title={row.restr}>{row.restr}</span> 
            },
            {
                field: 'time', header: 'Time',
                body: (row, column) => {
                    const formattedDate = this.utilsService.formatDateToDefault(row.time);
                    return <span title={formattedDate}>{formattedDate}</span>
                }
            },
            { 
                field: 'status', header: 'Status', sortable: true,
                body: (row) => <span title={row.status}>{row.status}</span> 
            },
            { 
                field: '', header: '', 
                hidden: showReplayLink ? false : true,
                body: (row, column) => {
                    if(row.showReplay) {
                        return <div onClick={(e) => e.stopPropagation()}>
                            <LinkContainer to={'/historical-replay/'+this.context?.User?.clientCode+'/'+row.driver+'/'+this.context?.User?.stateId+'/'+row.time}>
                                <Button label="Replay" className="p-button-link text-xs" />
                            </LinkContainer>
                        </div>
                    }
                }
            }
        ];

        return columns;
    }


    rowExpansionTemplate(activeRoute) {
        //const row1 = <div className="row-1 flex flex-row gap-3">
        //    <div className="flex flex-row gap-1">
        //        <div className="font-bold">Run: </div>
        //        <div>{activeRoute.runNumber} ({activeRoute.statusTime})</div>
        //    </div>
        //    <div className="flex flex-row gap-1">
        //        <div className="font-bold">Arrive Depot: </div>
        //        <div>No Data</div>
        //    </div>
        //    <div className="flex flex-row gap-1">
        //        <div className="font-bold">Load: </div>
        //        <div>({activeRoute.timeOnly})</div>
        //    </div>
        //    <div className="flex flex-row gap-1">
        //        <div className="font-bold">Depart Depot: </div>
        //        <div>({activeRoute.timeOnly})</div>
        //    </div>
        //    <div className="flex flex-row gap-1">
        //        <div className="font-bold">Drops Completed: </div>
        //        <div>{activeRoute.completed}/{activeRoute.completed + activeRoute.pending}</div>
        //    </div>
        //    <div className="flex-grow-1"></div>
        //    {/* <Button label="Map" className="p-button-sm text-xs" /> */}
        //</div>;

        //const row2 = <div className="row-2 flex flex-row gap-3">
        //    <div className="flex flex-row gap-1">
        //        <div className="font-bold">Total: </div>
        //        <div>N/A</div>
        //    </div>
        //    <div className="flex flex-row gap-1">
        //        <div className="font-bold">Demurrage: </div>
        //        <div>N/A</div>
        //    </div>
        //    <div className="flex flex-row gap-1">
        //        <div className="font-bold">Wait: </div>
        //        <div>N/A</div>
        //    </div>
        //    <div className="flex flex-row gap-1">
        //        <div className="font-bold">On Time: </div>
        //        <div>N/A</div>
        //    </div>
        //    <div className="flex flex-row gap-1">
        //        <div className="font-bold">Misc: </div>
        //        <div>N/A</div>
        //    </div>
        //    <div className="flex flex-row gap-1">
        //        <div className="font-bold">Restricted: </div>
        //        <div>N/A</div>
        //    </div>
        //</div>

        const tableColumns = this.generateDrilldownColumns(activeRoute.orderedJobsPerDropNumber ?? []);
        
        const dataTable = <DataTable 
            value={activeRoute.orderedJobsPerDropNumber} 
            className="p-datatable-sm p-datatable-gridlines p-datatable-striped" 
            rowHover={true}
            autoLayout={true}
            onRowClick={(e) => this.utilsService.openJobTrackingPage(e.data.iLogixJobNumber, this.context.User)}
        >
            {this.renderColumns(tableColumns)}
        </DataTable>
        
        return (
            <div className="sub-table flex flex-column gap-1">
                {/*{row1}*/}
                {/*{row2}*/}
                {dataTable}
            </div>
        );
    }


    renderActiveRoutes() {
        const viewIsSmallerThanLaptop = window.innerWidth <= 1200;
        
        if(this.props?.loadingActiveRoutes) {
            return <div className="flex flex-row align-items-center gap-2 p-2">
                <Spinner color="info" />
                <em>Please wait while we retrieve the active routes.</em>
            </div>
        } else if(this.props?.activeRoutes?.length === 0) {
            return <p><em>No active routes.</em></p>;
        } else {
            const tableColumns = this.generateColumns(this.props?.activeRoutes ?? []);

            return <DataTable 
                ref={(e) => this.activeRoutesTable = e} 
                value={this.props.activeRoutes} 
                className="p-datatable-sm p-datatable-gridlines p-datatable-striped" 
                paginator 
                rows={32}
                rowsPerPageOptions={[32, 50, 100]}
                expandedRows={this.state.expandedRows} 
                rowExpansionTemplate={(activeRoute) => this.rowExpansionTemplate(activeRoute)}
                rowHover={true}
                autoLayout={true}
                sortField={this.state?.sortField}
                sortOrder={this.state?.sortOrder}
                scrollable={viewIsSmallerThanLaptop ? false : true}
                scrollHeight={'calc(100vh - 280px)'}
            >
                {this.renderColumns(tableColumns)}
            </DataTable>
        }
    }


    renderColumns(columns) {
        const filteredColumns = columns?.filter(column => !column.hidden); /* Do not render columns with "hidden" flag */

        return filteredColumns?.map((column, index) => {
            return <Column 
                key={index} 
                field={column.field} 
                header={column.header} 
                filter={column.filter}
                filterMatchMode={'contains'}
                filterElement={column.filterElement} 
                sortable={column.sortable} 
                body={column.body}
            ></Column>
        })
    }


    render() {
        return <div className="active-routes">
            {this.renderActiveRoutes()}
        </div>
    }
} 


ActiveRoutes.contextType = ManagerContext;