import React, { Component } from 'react';
import { Container, Jumbotron } from 'reactstrap';

import ManagerContext from '../context/ManagerContext';


export class Landing extends Component {
    static displayName = Landing.name;

    render() {
        let context = this.context;

        if (context.IsLoggedIn) {
            return (

                <Container fluid>
                    <Jumbotron>
                        <h2>Welcome to iLogix</h2>
                        <p>Welcome to the iLogix application, a leading edge tracking platform. You can use the menu along the top of this site to navigate to other areas.</p>
                    </Jumbotron>
                </Container>
            );
        } else {

            return (

                <Container fluid>
                    <Jumbotron>
                        <h2>Welcome to iLogix</h2>
                        <p>Please log in through your carriers main home page. If you are unsure of this process, please speak with your transport providers representative.</p>
                    </Jumbotron>
                </Container>

            );
        }
    }
}

Landing.contextType = ManagerContext;
